
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


//Imports
import { AsyncStorage } from "@core/services/AsyncStorage";
import { Category, Product, ProductCategoryData } from '@core/data/product';
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

//Exports
export { AsyncStorage } from "@core/services/AsyncStorage";
export { Category, Product } from '@core/data/product';

@Injectable()
export class ProductsService {

    private _products: AsyncStorage<Product>;
    private _categories: AsyncStorage<Category>;
    private _activeProductsClientId: string;
    private _activeCategoriesClientId: string;
    private _targetClientId: string;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Categories
        this._categories = new AsyncStorage(this._http);
        this._categories._setAddFunction(this._save());
        this._categories._setElementComparisonFunction(this._categoryComparison);
        this._categories._setSaveActiveElementFunction(this._save());
        this._categories._setUpdateFunction(this._updateCategories());

        //Products
        this._products = new AsyncStorage(this._http);
        this._products._setAddFunction(this._save());
        this._products._setElementComparisonFunction(this._productComparison);
        this._products._setSaveActiveElementFunction(this._save());
        this._products._setUpdateFunction(this._updateProducts());


        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._categories.update();
                this._products.update();
            });
    }

    public get activeProductsClientId(): string { return this._activeProductsClientId };
    public get activeCategoriesClientId(): string { return this._activeCategoriesClientId };

    public get products() { return this._products };
    public get categories() { return this._categories };

    private _productComparison(a: Product, b: Product): boolean {
        //Compare the two product IDs
        return a && b ? a.productId === b.productId : false;
    }

    private _categoryComparison(a: Category, b: Category): boolean {
        //Compare the two product IDs
        return a && b ? a.categoryId === b.categoryId : false;
    }

    private _save(): (garbage: any, options?: any) => Observable<any> {

        let self = this;

        return (garbage: any, options?: any): Observable<any> => {

            let productsAndCategories: ProductCategoryData = new ProductCategoryData();

            //Set the client id
            productsAndCategories.clientId = self._targetClientId || self._auth.activeClient?.clientId;

            //Add products
            let products: Product[] = self._products.data.value;
            productsAndCategories.productData.products = [];
            for (let i in products) {
                let productId = products[i].productId;
                productsAndCategories.productData[productId] = products[i];
                productsAndCategories.productData.products.push(productId);
            }

            //Add categories
            let categories: Category[] = self._categories.data.value;
            productsAndCategories.categoryData.categories = [];
            for (let i in categories) {
                let categoryId = categories[i].categoryId;
                productsAndCategories.categoryData[categoryId] = categories[i];
                productsAndCategories.categoryData.categories.push(categoryId);
            }

            //Update or add the item
            return new Observable(observer => {
                this._http.post("products/update", productsAndCategories).toPromise()
                    .then((data: APIResponse) => {
                        if (0 == Object.keys(data.data).length || data.errors.length)
                            //Add if we get an error
                            this._http.post("products/add", productsAndCategories).toPromise()
                                .then((data: APIResponse) => { observer.next(data); })
                                .catch(error => { observer.next(); })
                        else
                            observer.next(data);
                    })
                    .catch(error => {
                        //Add if we get an error
                        this._http.post("products/add", productsAndCategories).toPromise()
                            .then((data: APIResponse) => { observer.next(data); })
                            .catch(error => { observer.next(); })

                    })
            })
        }

    }

    private _updateCategories(): () => Observable<Category[]> {
        let self = this;

        return () => {
            debug.log("Updating categories");

            return this._http.post("products/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Update the client id
                    this._activeCategoriesClientId = this._targetClientId;

                    //Convert the data
                    let productsAndCategories: ProductCategoryData = data.data;

                    //Create a new object if nothing was found
                    if (!productsAndCategories) productsAndCategories = new ProductCategoryData();

                    //No categories available
                    if (!productsAndCategories.categoryData) return [];

                    //Filter so only unique values remain - filter wasn't defined on main object
                    let tempArray = [];
                    for (let i in productsAndCategories.categoryData.categories) tempArray.push(productsAndCategories.categoryData.categories[i]);
                    productsAndCategories.categoryData.categories = tempArray.filter((item, pos) => {
                        return tempArray.indexOf(item) == pos;
                    })

                    //Parse the categories
                    let categories: Category[] = [];
                    for (let i in productsAndCategories.categoryData.categories) {
                        let categoryId = productsAndCategories.categoryData.categories[i]
                        categories.push(<Category>productsAndCategories.categoryData[categoryId])
                    }


                    //Sort alphabetically
                    categories.sort((leftSide: Category, rightSide: Category): number => {
                        if (leftSide.name < rightSide.name) return -1
                        if (leftSide.name > rightSide.name) return 1
                        return 0;
                    });

                    return categories;
                }));
        }

    }

    private _updateProducts(): () => Observable<Product[]> {
        let self = this;

        return () => {
            debug.log("Updating products");

            return this._http.post("products/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Update the client id
                    this._activeProductsClientId = this._targetClientId;

                    //Convert the data
                    let productsAndCategories: ProductCategoryData = data.data;

                    //Create a new object if nothing was found
                    if (!productsAndCategories) productsAndCategories = new ProductCategoryData();

                    //No products available
                    if (!productsAndCategories || !productsAndCategories.productData) return [];

                    //Filter so only unique values remain
                    let tempArray = [];
                    for (let i in productsAndCategories.productData.products) tempArray.push(productsAndCategories.productData.products[i]);
                    productsAndCategories.productData.products = tempArray.filter((item, pos) => {
                        return tempArray.indexOf(item) == pos;
                    })

                    //Parse the products
                    let products: Product[] = [];
                    for (let i in productsAndCategories.productData.products) {
                        let productId = productsAndCategories.productData.products[i]
                        products.push(<Product>productsAndCategories.productData[productId])
                    }

                    //Sort alphabetically
                    products.sort((leftSide: Product, rightSide: Product): number => {
                        if (leftSide.name < rightSide.name) return -1
                        if (leftSide.name > rightSide.name) return 1
                        return 0;
                    });

                    return products;
                }));
        }
    }

}
