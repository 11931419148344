import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Fulfillment, PaymentFulfillment, WashesFulfillment } from "@core/data/fullfillment";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { map } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';

export { WashesFulfillment, PaymentFulfillment };

const API_PATHS = {
  add: "fulfillment/add",
  get: "fulfillment/get",
  update: "fulfillment/update"
}

@Injectable()
export class FulfillmentService {

  private _washFulfillment: AsyncStorage<WashesFulfillment>;
  private _activeClientId: string;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._washFulfillment = new AsyncStorage(this._http);
    this._washFulfillment._setAddFunction(this._saveFulfillment());
    this._washFulfillment._setElementComparisonFunction(this._washFulfillmentComparison);
    this._washFulfillment._setSaveActiveElementFunction(this._saveFulfillment());
    this._washFulfillment._setUpdateFunction(this._updateWashFulfillment());

    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._washFulfillment.update();
      });

  }

  public get washFulfillment() { return this._washFulfillment };

  //There will only be one so its always the same one
  private _washFulfillmentComparison(a: WashesFulfillment, b: WashesFulfillment): boolean { return false }

  private _saveFulfillment(): (garbage: any, options?: any) => Observable<any> {

    let self = this;

    return (garbage: any, options?: any): Observable<any> => {

      //Add wash fulfillment
      let washFulfillment: WashesFulfillment = self._washFulfillment.activeElement || {};
      let paymentFulfillment: PaymentFulfillment = {};

      //Prepare the data to send
      let requestData: Fulfillment = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId,
        washes: washFulfillment,
        payment: paymentFulfillment
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post(API_PATHS.update, requestData).toPromise()
          .then((data: APIResponse) => {
            if (0 == Object.keys(data.data).length || data.errors.length)
              //Add if we get an error
              this._http.post(API_PATHS.add, requestData).toPromise()
                .then((data: APIResponse) => { observer.next(data); })
                .catch(error => { observer.next(); })
            else
              observer.next(data);
          })
          .catch(error => {
            //Add if we get an error
            this._http.post(API_PATHS.add, requestData).toPromise()
              .then((data: APIResponse) => { observer.next(data); })
              .catch(error => { observer.next(); })

          })
      })
    }

  }

  private _updateWashFulfillment(): () => Observable<WashesFulfillment[]> {
    let self = this;

    return () => {
      debug.log("Updating wash fulfillment");

      return this._http.post(API_PATHS.get, { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
        map((data: APIResponse) => {

          //Convert the data
          let washFulfillment: WashesFulfillment = !data.errors.length ? data.data.washes || {} : {};

          return [washFulfillment];

        }));
    }
  }





}