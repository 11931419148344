export class UserReportData {
    /**
    *  This is to store any users that have been saved in the dynamo database but not in the sql database. This 
    *  does not come from the api call.
    */
    CachedUsers?: UserReport[];

    /**
    *  The lasts evaluated key
    */
    LastEvaluatedKey: number;

    /**
    *  The number of records returned "capped"
    */
    Count: number;

    /**
    *  Users
    */
    Items: UserReport[];

    /**
    *  Total number of users found in the database
    */
    TotalItems: number;

    /**
    *  The number of pages that can be paginated through
    */
    TotalPages: number;

    /**
    *  Current page
    */
    Page: number;

    /**
    *  the number of items on the current page
    */
    PageSize: number;
}

/**
  *  The user object.
  */
export class UserReport {
    /**
    *  If the device has a cellular internet connection.
    */
    cellularConnection?: string;

    /**
    *  user client ID
    */
    clientId: string;

    /**
    *  Date the user was created on
    */
    createdOn?: number;

    /**
    *  Custom User Data
    */
    customUserData?: object;

    /**
    *  The most reason active device on the user's account
    */
    device?: string;

    /**
    *  Number of free carwashes
    */
    freeWashCount: number;

    /**
    *  User's email address
    */
    email: string;

    /**
    *  User ID
    */
    userId: string;

    /**
    *  User type
    */
    type: "customer" | "client" | "cwc";

    /**
    *  Loyalty points owned by that user
    */
    loyalty?: UserReportLoyalty[];

    /**
    *  Subscriptions that the user has that are currently active
    */
    subscriptions?: UserReportSubscription[];

    /**
    *  User's first name
    */
    firstName?: string;

    /**
    *  user's last name
    */
    lastName?: string;

    /**
    *  The last tiome that the user's record has been modified
    */
    modifiedOn?: number;

    /**
    *  The operating system that the device is running.
    */
    osType?: string;

    /**
    *  The Operating System that the device is running
    */
    osVersion?: string;

    /**
    *  Number of single sale purchases
    */
    ssPurchaseCount: number;

    /**
    *  Number of single sale purchases that have been used
    */
    ssUsedCount: number;

    /**
    *  Number of refunds that this user has done
    */
    refundCount: number;

    /**
    *  Any flags that are associated with this user's account
    */
    flags?: string;

    /**
    *  role of this user
    */
    role?: "unknown" | "admin" | "superAdmin";

    /**
    *  current status of the user's account
    */
    status: string;

    /**
    *  If the user has logged into the app or admin portal.
    */
    isNewUser: boolean;

}

export interface UserReportLoyalty {
    /**
    *  Number of loyalty points that the user has
    */
    balance: number;

    /**
    *  the loyalty program that is associated with the user's loyalty points
    */
    loyaltyProgramId: string;
}

export interface UserReportSubscription {
    /**
    *  location that the subscription was puchased at
    */
    purchaseLocationId: string;

    /**
    *  last time the subscription was renewed
    */
    renewed: number;

    /**
    *  Next time the subscription renews
    */
    renews: number;

    /**
    *  the sale id of the subscription
    */
    saleId: string;

    /**
    *  sharing groups for the subscription
    */
    sharing: {
        groupId?: string;
        snapShot: string[];
    }

    /**
    *  subscription ID
    */
    subscriptionId: string;

    /**
    *  The date the subscription started on
    */
    subscriptionStartedOn?: number;

    /**
    *  number of washcodes used this month for this subscription
    */
    washCodesUsed: number;
}