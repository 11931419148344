
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest } from "rxjs";

import { AsyncStorage } from '@core/services/AsyncStorage';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { APIResponse } from '@shared/types/APIResponse';
import { DebugService as debug } from "@core/services/debug.service";
import { Objects } from '@shared/lib/Objects';
import { UserService, User } from '@admin/users/user.service';
import { AuthService } from '@core/services/auth/auth.service';

export { CustomUserDataEntry }

@Injectable()
export class CustomDataService {

    private _customUserData: AsyncStorage<any>;
    private _targetClientId: string;
    private _targetUserId: string;
    private _activeCustomUserDataClientId: string;
    private _activeUserId: string;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _usersService: UserService,
        private _auth: AuthService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare stripe storage
        this._customUserData = new AsyncStorage(this._http);
        this._customUserData._setElementComparisonFunction(this._customFieldComparison);
        this._customUserData._setSaveActiveElementFunction(this._saveCustomUserData());
        this._customUserData._setUpdateFunction(this._updateCustomUserData());

        combineLatest([
            this._clientsService.clients.onActiveElementChange(),
            this._usersService.user.onUpdate()
        ]).subscribe((data: any[]) => {
            let client: Client = data[0];
            let user: User = data[1][0];
            if (user.clientId != client.clientId) return;

            this._targetClientId = client.clientId;
            this._targetUserId = user.userId;
            this._customUserData.update();
        });

    }

    public get activeCustomUserDataClientId(): string { return this._activeCustomUserDataClientId };
    public get activeCustomUserDataUserId(): string { return this._activeUserId };
    public get customUserData() { return this._customUserData };

    private _customFieldComparison(a: CustomUserDataEntry, b: CustomUserDataEntry): boolean {

        //Copy the objects
        let dataA = Objects.copy(a);
        let dataB = Objects.copy(b);

        //Compare the two
        return JSON.stringify(dataA) === JSON.stringify(dataB);

    }

    private _saveCustomUserData(): (garbage: any, options?: any) => Observable<any> {

        let self: CustomDataService = this;

        return (garbage: any, options?: any): Observable<any> => {

            let customUserData: CustomUserDataEntry[] = self._customUserData.data.value;
            let requestData: any = {
                clientId: self.activeCustomUserDataClientId || self._auth.activeClient?.clientId,
                userId: this._activeUserId,
                data: customUserData[0]
            };

            //Update or add the item
            return new Observable(observer => {
                this._http.post("customData/updateUserData", requestData).toPromise()
                    .then((data: APIResponse) => {
                        if (0 == Object.keys(data.data).length || Object.keys(data.errors).length)
                            observer.next();
                        else
                            observer.next(data);
                    })
                    .catch(error => {
                        observer.next();
                    })
            })
        }

    }

    private _updateCustomUserData(): (client?: Client) => Observable<any> {

        let self = this;
        return () => {

            debug.log("Updating custom user data");
            let requestData = {
                clientId: self._targetClientId || self._auth.activeClient?.clientId,
                userId: this._targetUserId
            }

            return this._http.post("customData/getUserData", requestData).pipe(
                map((data: APIResponse) => {

                    //Copy the clientId
                    this._activeCustomUserDataClientId = this._targetClientId;

                    //Grab the data
                    let customUserData: CustomUserDataEntries = data.data;

                    this._activeUserId = this._targetUserId;
                    this._activeCustomUserDataClientId = this._targetClientId;

                    // return all custom fields
                    return customUserData;

                }));
        }

    }

}

interface CustomUserDataEntries {
    [fieldId: string]: CustomUserDataEntry[];
}

interface CustomUserDataEntry {
    fieldId: string;
    subFieldA: string;
    subFieldB?: string;
}
