export class UnitecData {
    unitecIds: string[] = [];
    unitecAccount: { [key: string]: UnitecAccount } = {};
    lastId: number = 0;
    applications: { [key: string]: { accountId: string } } = {};

}

export class UnitecAccount {
    unitecId: string;
    nickname: string;
    availableLocations?: string[];

    constructor(account?: UnitecAccount) {
        this.unitecId = account.unitecId || "unitecAccount";
        this.nickname = account.nickname || "";
        this.availableLocations = account.availableLocations || []
    }
}