import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogText } from "@core/data/DialogText";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  public text: DialogText = {
    title: "Confirm",
    message: "Are you sure?",
    list:[],
    buttons: {
      affirmative: "Yes",
      negative: "No"
    }
  }

  public colour: string = "warn";
  public inverseButtonColours: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _text?: DialogText,
  ) {
    if (_text) {
      this.text.title = _text.title || this.text.title;
      this.text.message = _text.message || this.text.message;
      this.text.list = _text.list || this.text.list;
      if (_text.buttons) {

        //Colours
        this.inverseButtonColours = _text.buttons.inverseButtonColours || this.inverseButtonColours;
        this.colour = _text.buttons.colour || this.colour;

        //Text
        this.text.buttons.affirmative = _text.buttons.affirmative || this.text.buttons.affirmative;
        this.text.buttons.negative = _text.buttons.negative || this.text.buttons.negative;
      }
    }
  }

}

