import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { ICSData, ICSAccount, PLUData } from "@core/data/controller";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { KesseltronicsAccount, KesseltronicsData, KesseltronicsApplication } from '@core/data/kesseltronics';
import { UnitecAccount, UnitecData } from '@core/data/unitec';
import { map } from 'rxjs/operators';
import { Objects } from '@shared/lib/Objects';
import { AuthService } from '@core/services/auth/auth.service';

export { ICSAccount };

type AccountObject<AccountType> = { [accountId: string]: AccountType };
type ApplicationObject<ApplicationType> = { [locationId: string]: ApplicationType };

@Injectable()
export class ThirdPartyService {

    private _icsAccounts: AsyncStorage<ICSAccount>;
    private _cachedICSAccounts: AccountObject<ICSAccount>;
    private _icsPLUs: AsyncStorage<PLUData>;
    private _cachedICSApplications: ApplicationObject<PLUData>;

    private _kesseltronicsAccounts: AsyncStorage<KesseltronicsAccount>;
    private _cachedKesseltronicsAccounts: AccountObject<KesseltronicsAccount>;
    private _kesseltronicsApplications: AsyncStorage<KesseltronicsApplication>;
    private _cachedKesseltronicsApplications: ApplicationObject<KesseltronicsApplication>;

    private _unitecAccounts: AsyncStorage<UnitecAccount>;
    private _cachedUnitecAccounts: AccountObject<UnitecAccount>;

    private _activeClientId: string;
    private _lastUsedICSId: number = 0;
    private _lastUsedKesseltronicsId: number = 0;
    private _lastUsedUnitecId: number = 0;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        // debug.log("Third party connection service id:", Random.string(8))
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare ICS Account storage
        this._icsAccounts = new AsyncStorage<ICSAccount>(this._http);
        this._icsAccounts._setAddFunction(this._saveICSAccounts());
        this._icsAccounts._setElementComparisonFunction(this._icsAccountComparison);
        this._icsAccounts._setSaveActiveElementFunction(this._saveICSAccounts());
        this._icsAccounts._setUpdateFunction(this._updateICSAccounts());

        //Prepare ICS PLU storage
        this._icsPLUs = new AsyncStorage<PLUData>(this._http);
        this._icsPLUs._setAddFunction(this._saveICSAccounts());
        this._icsPLUs._setElementComparisonFunction(this._icsPLUComparison);
        this._icsPLUs._setSaveActiveElementFunction(this._saveICSAccounts());
        this._icsPLUs._setUpdateFunction(this._updateICSPLUs());

        //Prepare Kesseltronics Account storage
        this._kesseltronicsAccounts = new AsyncStorage<KesseltronicsAccount>(this._http);
        this._kesseltronicsAccounts._setAddFunction(this._saveKesseltronicsAccounts());
        this._kesseltronicsAccounts._setElementComparisonFunction(this._kesseltronicsAccountComparison);
        this._kesseltronicsAccounts._setSaveActiveElementFunction(this._saveKesseltronicsAccounts());
        this._kesseltronicsAccounts._setUpdateFunction(this._updateKesseltronicsAccounts());

        //Prepare Kesseltronics Application storage
        this._kesseltronicsApplications = new AsyncStorage<KesseltronicsApplication>(this._http);
        this._kesseltronicsApplications._setAddFunction(this._saveKesseltronicsAccounts());
        this._kesseltronicsApplications._setElementComparisonFunction(this._kesseltronicsApplicationComparison);
        this._kesseltronicsApplications._setSaveActiveElementFunction(this._saveKesseltronicsAccounts());
        this._kesseltronicsApplications._setUpdateFunction(this._updateKesseltronicsApplications());

        //Prepare Unitec Account storage
        this._unitecAccounts = new AsyncStorage<UnitecAccount>(this._http);
        this._unitecAccounts._setAddFunction(this._saveUnitecAccounts());
        this._unitecAccounts._setElementComparisonFunction(this._unitecAccountComparison);
        this._unitecAccounts._setSaveActiveElementFunction(this._saveUnitecAccounts());
        this._unitecAccounts._setUpdateFunction(this._updateUnitecAccounts());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._activeClientId = client.clientId;
                this._icsAccounts.update();
                this._icsPLUs.update();
                this._kesseltronicsAccounts.update();
                this._kesseltronicsApplications.update();
                // this._unitecAccounts.update();
            });

        this._icsAccounts.onUpdate().subscribe((accounts: ICSAccount[]) => {
            this._cacheAccounts("ics", accounts);
        })
        this._icsPLUs.onUpdate().subscribe((applications: PLUData[]) => {
            this._cacheApplications("ics", applications);
        })

        this._kesseltronicsAccounts.onUpdate().subscribe((accounts: KesseltronicsAccount[]) => {
            this._cacheAccounts("kesseltronics", accounts);
        })
        this._kesseltronicsApplications.onUpdate().subscribe((applications: KesseltronicsApplication[]) => {
            this._cacheApplications("kesseltronics", applications);
        })

        this._unitecAccounts.onUpdate().subscribe((accounts: UnitecAccount[]) => {
            this._cacheAccounts("unitec", accounts);
        })

    }

    public get icsAccounts() { return this._icsAccounts };
    public get icsPLUs() { return this._icsPLUs };
    public get nextICSId(): string { return this._lastUsedICSId++, "icsAccount" + this._lastUsedICSId; };

    public get kesseltronicsAccounts() { return this._kesseltronicsAccounts };
    public get kesseltronicsApplications() { return this._kesseltronicsApplications };
    public get nextKesseltronicsId(): string { return this._lastUsedKesseltronicsId++, "kesseltronicsAccount" + this._lastUsedKesseltronicsId; };

    public get unitecAccounts() { return this._unitecAccounts };
    public get nextUnitecId() { return this._lastUsedUnitecId++, "unitecAccount" + this._lastUsedUnitecId; };

    /**
      * Caches accounts; used for detecting changes when saving.
      */
    private _cacheAccounts<T extends keyof AccountTypes>(type: T, accounts: (AccountTypes[T])[]): void {
        switch (type) {
            case "ics":
                this._cachedICSAccounts = this._accountToObject("ics", Objects.copy(accounts));
                break;
            case "kesseltronics":
                this._cachedKesseltronicsAccounts = this._accountToObject("kesseltronics", Objects.copy(accounts));
                break;
            case "unitec":
                this._cachedUnitecAccounts = this._accountToObject("unitec", Objects.copy(accounts));
                break;
            default:
                break;
        }
    }

    /**
      * Caches applications; used for detecting changes when saving.
      */
    private _cacheApplications<T extends keyof ApplicationTypes>(type: T, applications: (ApplicationTypes[T])[]): void {
        switch (type) {
            case "ics":
                this._cachedICSApplications = this._applicationToObject("ics", Objects.copy(applications));
                break;
            case "kesseltronics":
                this._cachedKesseltronicsApplications = this._applicationToObject("kesseltronics", Objects.copy(applications));
                break;
            case "unitec":
                // this._cachedUnitecApplications = this._applicationToObject("unitec", Objects.copy(applications));
                break;
            default:
                break;
        }
    }

    /**
      * Converts an array of accounts into an object where the account id is the key; used for detecting changes when saving.
      */
    private _accountToObject<T extends keyof AccountTypes>(type: T, accounts: (AccountTypes[T])[]): AccountObject<AccountTypes[T]> {
        let accountObject: AccountObject<AccountTypes[T]> = {};
        for (let account of accounts) {
            switch (type) {
                case "ics":
                    accountObject[(<ICSAccount>account).ICSId] = account;
                    break;
                case "kesseltronics":
                    accountObject[(<KesseltronicsAccount>account).kesseltronicsId] = account;
                    break;
                case "unitec":
                    accountObject[(<UnitecAccount>account).unitecId] = account;
                    break;
                default:
            }
        }
        return accountObject;
    }

    /**
      * Converts an array of applications into an object where the location id is the key; used for detecting changes when saving.
      */
    private _applicationToObject<T extends keyof ApplicationTypes>(type: T, applications: (ApplicationTypes[T])[]): AccountObject<ApplicationTypes[T]> {
        let applicationObject: ApplicationObject<ApplicationTypes[T]> = {};
        for (let application of applications) {
            let locationId: string;
            switch (type) {
                case "ics":
                    locationId = (<PLUData>application).locationId;
                    applicationObject[locationId] = application;
                    break;
                case "kesseltronics":
                    locationId = (<KesseltronicsApplication>application).locationId;
                    applicationObject[locationId] = application;
                    break;
                case "unitec":
                    break;
                default:
            }
        }
        return applicationObject;
    }


    private _icsAccountComparison(a: ICSAccount, b: ICSAccount): boolean {

        //Compare the two
        return a != null && b != null ? a.ICSId === b.ICSId : false;

    }

    private _icsPLUComparison(a: PLUData, b: PLUData): boolean {

        //Compare the two
        return a != null && b != null ? a.locationId === b.locationId : false;

    }

    private _kesseltronicsAccountComparison(a: KesseltronicsAccount, b: KesseltronicsAccount): boolean {

        //Compare the two
        return a != null && b != null ? a.kesseltronicsId === b.kesseltronicsId : false;

    }

    private _kesseltronicsApplicationComparison(a: KesseltronicsApplication, b: KesseltronicsApplication): boolean {

        //Compare the two
        return a != null && b != null ? a.locationId === b.locationId : false;

    }

    private _unitecAccountComparison(a: UnitecAccount, b: UnitecAccount): boolean {

        //Compare the two
        return a != null && b != null ? a.unitecId === b.unitecId : false;

    }



    private _saveICSAccounts(): (garbage: any, options?: any) => Observable<void> {

        let self = this;

        return (garbage: any, options?: any): Observable<void> => {

            let getUpdatedICSData: () => Promise<ICSData> = async () => {
                try {

                    let clientId: string = self._activeClientId || self._auth.activeClient?.clientId;

                    //Load the current ics data
                    let getResponse: APIResponse = <APIResponse>await this._http.post("ics/get", { clientId: clientId }).toPromise();

                    //Convert the data
                    let remoteClientId: string = (getResponse.data || {}).clientId || clientId;
                    let remoteIcsData: ICSData = (getResponse.data || {}).data;

                    //Ensure configuration data matches
                    if (!remoteIcsData || !Object.keys(remoteIcsData).length) remoteIcsData = new ICSData();
                    if (clientId != remoteClientId) return Promise.reject(new Error("Client id mismatch."));

                    //Calculate differences between accounts relative to the remote
                    let remoteAccounts: AccountObject<ICSAccount> = remoteIcsData.ICSAccounts;
                    let localAccounts: AccountObject<ICSAccount> = this._accountToObject("ics", this._icsAccounts.data.value);
                    let accountChanges: { additions?: any, deletions?: any, updates?: any } = Objects.compare(this._cachedICSAccounts, localAccounts);
                    let accountUpdates: any = Objects.merge(accountChanges.additions || {}, accountChanges.updates || {});

                    //Update remote account data
                    //This may re-add deleted accounts
                    for (let accountId in accountUpdates) {
                        if (remoteAccounts[accountId])
                            remoteAccounts[accountId] = Objects.merge(remoteAccounts[accountId] || {}, accountUpdates[accountId]);
                        else
                            remoteAccounts[accountId] = Objects.merge(localAccounts[accountId] || {}, accountUpdates[accountId]);
                    }

                    //Delete remote account data
                    if (accountChanges.deletions)
                        remoteAccounts = Objects.subtract(remoteAccounts, accountChanges.deletions)


                    //Figure out the last used id
                    let accountIdList: string[] = Object.keys(remoteAccounts).sort();
                    let lastId = Number(accountIdList[accountIdList.length - 1].replace("icsAccount", ""));
                    lastId = this._lastUsedICSId > lastId ? this._lastUsedICSId : lastId;


                    //Calculate differences between applications relative to the remote
                    let remoteApplications: { [locationId: string]: PLUData } = remoteIcsData.applications;
                    let localApplications: { [locationId: string]: PLUData } = {}
                    for (let application of self._icsPLUs.data.value) {
                        localApplications[application.locationId] = application;
                    }
                    let applicationChanges: { additions?: any, deletions?: any, updates?: any } = Objects.compare(this._cachedICSApplications, localApplications);
                    let applicationUpdates: any = Objects.merge(applicationChanges.additions || {}, applicationChanges.updates || {});

                    //Update remote application data
                    //This may re-add deleted application
                    for (let locationId in applicationUpdates) {
                        if (remoteApplications[locationId])
                            remoteApplications[locationId] = Objects.merge(remoteApplications[locationId] || {}, applicationUpdates[locationId]);
                        else
                            remoteApplications[locationId] = Objects.merge(localApplications[locationId] || {}, applicationUpdates[locationId]);
                    }

                    //Delete remote account data
                    if (applicationChanges.deletions)
                        remoteApplications = Objects.subtract(remoteApplications, applicationChanges.deletions)


                    //Any updates that are required for all accounts
                    for (let accountId in remoteAccounts) {
                        let account: ICSAccount = remoteAccounts[accountId];
                        account.availableLocations = account.availableLocations || [];
                        if (account.availableLocations && !Array.isArray(account.availableLocations))
                            account.availableLocations = Object.values(account.availableLocations);
                        account.version = account.version || "1.7";
                    }

                    //Create the final configuration object
                    let completeData: ICSData = {
                        ICSAccounts: remoteAccounts,
                        ICSIds: accountIdList,
                        applications: remoteApplications,
                        lastId: lastId
                    }

                    debug.log("ICS Account Changes:", accountChanges);
                    debug.log("ICS Application Changes:", applicationChanges);
                    debug.log("ICS complete data:", completeData);

                    //Return the ics data
                    return Promise.resolve(completeData);

                } catch (e) {
                    return Promise.reject(e);
                }
            };

            //Update or add the item
            return new Observable(observer => {
                getUpdatedICSData()
                    .then((icsAccountData) => {

                        //Prepare the data to send
                        let requestData = {
                            clientId: self._activeClientId || self._auth.activeClient?.clientId,
                            data: icsAccountData
                        };

                        this._http.post("ics/update", requestData).toPromise()
                            .then((data: APIResponse) => {
                                if (0 == Object.keys(data.data).length || Object.keys(data.errors).length)
                                    //Add if we get an error
                                    this._http.post("ics/add", requestData).toPromise()
                                        .then((data: APIResponse) => { observer.next(); })
                                        .catch(error => { observer.error(error); })
                                else
                                    observer.next();
                            })
                            .catch(error => {
                                //Add if we get an error
                                this._http.post("ics/add", requestData).toPromise()
                                    .then((data: APIResponse) => { observer.next(); })
                                    .catch(error => { observer.error(error); })

                            })
                    })
                    .catch((e) => {
                        debug.error(e);
                        observer.error(e);
                    })
            })
        }

    }

    private _updateICSAccounts(): () => Observable<ICSAccount[]> {
        let self = this;

        return () => {
            debug.log("Updating ics accounts");

            return this._http.post("ics/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Convert the data
                    let icsAccountData: ICSData = !data.errors.length ? data.data.data : null;

                    //Create a new object if nothing was found
                    if (!icsAccountData) icsAccountData = new ICSData();

                    //Convert the icsAccounts into an array
                    let icsAccounts: ICSAccount[] = [];
                    for (let i in icsAccountData.ICSAccounts) {
                        icsAccounts.push(new ICSAccount(icsAccountData.ICSAccounts[i]));
                    }

                    //Store the last used id
                    this._lastUsedICSId = icsAccountData.lastId;

                    //Sort alphabetically
                    icsAccounts.sort((leftSide: ICSAccount, rightSide: ICSAccount): number => {
                        if (leftSide.nickname < rightSide.nickname) return -1
                        if (leftSide.nickname > rightSide.nickname) return 1
                        return 0;
                    });

                    return icsAccounts;
                }));
        }
    }

    private _updateICSPLUs(): () => Observable<PLUData[]> {
        let self = this;

        return () => {
            debug.log("Updating ics PLUs");

            return this._http.post("ics/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Convert the data
                    let icsAccountData: ICSData = !data.errors.length ? data.data.data : null;

                    //Create a new object if nothing was found
                    if (!icsAccountData) icsAccountData = new ICSData();

                    //Convert the icsAccounts into an array
                    let icsPLUs: PLUData[] = [];
                    for (let i in icsAccountData.applications) {
                        icsPLUs.push(icsAccountData.applications[i]);
                    }

                    //Store the last used id
                    this._lastUsedICSId = icsAccountData.lastId;

                    //Sort alphabetically
                    // icsPLUs.sort((leftSide: ICSAccount, rightSide: ICSAccount): number => {
                    //   if (leftSide.nickname < rightSide.nickname) return -1
                    //   if (leftSide.nickname > rightSide.nickname) return 1
                    //   return 0;
                    // });

                    return icsPLUs;
                }));
        }
    }

    private _saveKesseltronicsAccounts(): (garbage: any, options?: any) => Observable<void> {

        let self = this;

        return (garbage: any, options?: any): Observable<void> => {

            let getUpdatedKesseltronicsData: () => Promise<KesseltronicsData> = async () => {
                try {

                    let clientId: string = self._activeClientId || self._auth.activeClient?.clientId;

                    //Load the current ics data
                    let getResponse: APIResponse = <APIResponse>await this._http.post("kesseltronics/get", { clientId: clientId }).toPromise();

                    //Convert the data
                    let remoteClientId: string = (getResponse.data || {}).clientId || clientId;
                    let remoteKesseltronicsData: KesseltronicsData = (getResponse.data || {}).data;

                    //Ensure configuration data matches
                    if (!remoteKesseltronicsData || !Object.keys(remoteKesseltronicsData).length) remoteKesseltronicsData = new KesseltronicsData();
                    if (clientId != remoteClientId) return Promise.reject(new Error("Client id mismatch."));

                    //Calculate differences between accounts relative to the remote
                    let remoteAccounts: AccountObject<KesseltronicsAccount> = remoteKesseltronicsData.kesseltronicsAccount;
                    let localAccounts: AccountObject<KesseltronicsAccount> = this._accountToObject("kesseltronics", this._kesseltronicsAccounts.data.value);
                    let accountChanges: { additions?: any, deletions?: any, updates?: any } = Objects.compare(this._cachedKesseltronicsAccounts, localAccounts);
                    let accountUpdates: any = Objects.merge(accountChanges.additions || {}, accountChanges.updates || {});

                    //Update remote account data
                    //This may re-add deleted accounts
                    for (let accountId in accountUpdates) {
                        if (remoteAccounts[accountId])
                            remoteAccounts[accountId] = Objects.merge(remoteAccounts[accountId] || {}, accountUpdates[accountId]);
                        else
                            remoteAccounts[accountId] = Objects.merge(localAccounts[accountId] || {}, accountUpdates[accountId]);
                    }

                    //Delete remote account data
                    if (accountChanges.deletions)
                        remoteAccounts = Objects.subtract(remoteAccounts, accountChanges.deletions)


                    //Figure out the last used id
                    let accountIdList: string[] = Object.keys(remoteAccounts).sort();
                    let lastId = Number(accountIdList[accountIdList.length - 1].replace("kesseltronicsAccount", ""));
                    lastId = this._lastUsedKesseltronicsId > lastId ? this._lastUsedKesseltronicsId : lastId;


                    //Calculate differences between applications relative to the remote
                    let remoteApplications: { [locationId: string]: KesseltronicsApplication } = remoteKesseltronicsData.applications;
                    let localApplications: { [locationId: string]: KesseltronicsApplication } = {}
                    for (let application of self._kesseltronicsApplications.data.value) {
                        localApplications[application.locationId] = application;
                    }
                    let applicationChanges: { additions?: any, deletions?: any, updates?: any } = Objects.compare(this._cachedKesseltronicsApplications, localApplications);
                    let applicationUpdates: any = Objects.merge(applicationChanges.additions || {}, applicationChanges.updates || {});

                    //Update remote application data
                    //This may re-add deleted application
                    for (let locationId in applicationUpdates) {
                        if (remoteApplications[locationId])
                            remoteApplications[locationId] = Objects.merge(remoteApplications[locationId] || {}, applicationUpdates[locationId]);
                        else
                            remoteApplications[locationId] = Objects.merge(localApplications[locationId] || {}, applicationUpdates[locationId]);
                    }

                    //Delete remote account data
                    if (applicationChanges.deletions)
                        remoteApplications = Objects.subtract(remoteApplications, applicationChanges.deletions)

                    //Convert the available locations array back into an array
                    for (let accountId in remoteAccounts) {
                        let account: KesseltronicsAccount = remoteAccounts[accountId];
                        if (account.availableLocations && !Array.isArray(account.availableLocations))
                            account.availableLocations = Object.values(account.availableLocations);
                    }

                    //Create the final configuration object
                    let completeData: KesseltronicsData = {
                        kesseltronicsAccount: remoteAccounts,
                        kesseltronicsIds: accountIdList,
                        applications: remoteApplications,
                        lastId: lastId
                    }

                    debug.log("Kesseltronics Account Changes:", accountChanges);
                    debug.log("Kesseltronics Application Changes:", applicationChanges);
                    debug.log("Kesseltronics complete data:", completeData);

                    //Return the ics data
                    return Promise.resolve(completeData);

                } catch (e) {
                    return Promise.reject(e);
                }
            };

            //Update or add the item
            return new Observable(observer => {
                getUpdatedKesseltronicsData()
                    .then((kesseltronicsAccountData) => {

                        //Prepare the data to send
                        let requestData = {
                            clientId: self._activeClientId || self._auth.activeClient?.clientId,
                            data: kesseltronicsAccountData
                        };
                        this._http.post("kesseltronics/update", requestData).toPromise()
                            .then((data: APIResponse) => {
                                if (0 == Object.keys(data.data).length || Object.keys(data.errors).length)
                                    //Add if we get an error
                                    this._http.post("kesseltronics/add", requestData).toPromise()
                                        .then((data: APIResponse) => { observer.next(); })
                                        .catch(error => { observer.next(); })
                                else
                                    observer.next();
                            })
                            .catch(error => {
                                //Add if we get an error
                                this._http.post("kesseltronics/add", requestData).toPromise()
                                    .then((data: APIResponse) => { observer.next(); })
                                    .catch(error => { observer.next(); })
                            })
                    })
                    .catch((e) => {
                        debug.error(e);
                        observer.error(e);
                    })
            })
        }

    }

    private _updateKesseltronicsAccounts(): () => Observable<KesseltronicsAccount[]> {
        let self = this;

        return () => {
            debug.log("Updating kesseltronics accounts");

            return this._http.post("kesseltronics/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Convert the data
                    let kesseltronicsAccountData: KesseltronicsData = !data.errors.length ? data.data.data : null;

                    //Create a new object if nothing was found
                    if (!kesseltronicsAccountData) kesseltronicsAccountData = new KesseltronicsData();

                    //Convert the kesseltronics Accounts into an array
                    let kesseltronicsAccounts: KesseltronicsAccount[] = [];
                    for (let i of kesseltronicsAccountData.kesseltronicsIds) {
                        kesseltronicsAccounts.push(new KesseltronicsAccount(kesseltronicsAccountData.kesseltronicsAccount[i]));
                    }

                    //Store the last used id
                    this._lastUsedKesseltronicsId = kesseltronicsAccountData.lastId;


                    //Sort alphabetically
                    kesseltronicsAccounts.sort((leftSide: KesseltronicsAccount, rightSide: KesseltronicsAccount): number => {
                        if (leftSide.nickname < rightSide.nickname) return -1
                        if (leftSide.nickname > rightSide.nickname) return 1
                        return 0;
                    });

                    return kesseltronicsAccounts;
                }));
        }
    }

    private _updateKesseltronicsApplications(): () => Observable<KesseltronicsApplication[]> {
        let self = this;

        return () => {
            debug.log("Updating kesseltronics applications");

            return this._http.post("kesseltronics/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Convert the data
                    let kesseltronicsAccountData: KesseltronicsData = !data.errors.length ? data.data.data : null;

                    //Create a new object if nothing was found
                    if (!kesseltronicsAccountData) kesseltronicsAccountData = new KesseltronicsData();

                    //Convert the icsAccounts into an array
                    let kesseltronicsApplications: KesseltronicsApplication[] = [];
                    for (let i in kesseltronicsAccountData.applications) {
                        kesseltronicsApplications.push(kesseltronicsAccountData.applications[i]);
                    }

                    return kesseltronicsApplications;

                }));
        }
    }

    private _saveUnitecAccounts(): (garbage: any, options?: any) => Observable<any> {

        let self = this;

        return (garbage: any, options?: any): Observable<any> => {

            //Add unitec
            let unitecAccounts: UnitecAccount[] = self._unitecAccounts.data.value;
            let unitecAccountData: UnitecData = new UnitecData();

            //Prepare the object for storing
            for (let unitecAccount of unitecAccounts) {

                //Add the unitec account
                unitecAccountData.unitecIds.push(unitecAccount.unitecId);
                unitecAccountData.unitecAccount[unitecAccount.unitecId] = unitecAccount;

            }

            //Set the final used index
            unitecAccountData.lastId = this._lastUsedUnitecId;

            //Prepare the data to send
            let requestData = {
                clientId: self._activeClientId || self._auth.activeClient?.clientId,
                data: unitecAccountData
            };

            //Update or add the item
            return new Observable(observer => {
                this._http.post("unitec/update", requestData).toPromise()
                    .then((data: APIResponse) => {
                        if (0 == Object.keys(data.data).length || Object.keys(data.errors).length)
                            //Add if we get an error
                            this._http.post("unitec/add", requestData).toPromise()
                                .then((data: APIResponse) => { observer.next(data); })
                                .catch(error => { observer.next(); })
                        else
                            observer.next(data);
                    })
                    .catch(error => {
                        //Add if we get an error
                        this._http.post("unitec/add", requestData).toPromise()
                            .then((data: APIResponse) => { observer.next(data); })
                            .catch(error => { observer.next(); })

                    })
            })
        }

    }

    private _updateUnitecAccounts(): () => Observable<UnitecAccount[]> {
        let self = this;

        return () => {
            debug.log("Updating unitec accounts");

            return this._http.post("unitec/get", { clientId: self._activeClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    //Convert the data
                    let unitecAccountData: UnitecData = !data.errors.length ? data.data.data : null;

                    //Create a new object if nothing was found
                    if (!unitecAccountData) unitecAccountData = new UnitecData();

                    //Convert the icsAccounts into an array
                    let unitecAccounts: UnitecAccount[] = [];
                    for (let i in unitecAccountData.unitecIds) {
                        unitecAccounts.push(new UnitecAccount(unitecAccountData.unitecAccount[i]));
                    }

                    //Store the last used id
                    this._lastUsedUnitecId = unitecAccountData.lastId;

                    //Sort alphabetically
                    unitecAccounts.sort((leftSide: UnitecAccount, rightSide: UnitecAccount): number => {
                        if (leftSide.nickname < rightSide.nickname) return -1
                        if (leftSide.nickname > rightSide.nickname) return 1
                        return 0;
                    });

                    return unitecAccounts;
                }));
        }
    }

}

interface AccountTypes {
    ics: ICSAccount,
    kesseltronics: KesseltronicsAccount,
    unitec: UnitecAccount
}

interface ApplicationTypes {
    ics: PLUData,
    kesseltronics: KesseltronicsApplication,
    unitec: UnitecAccount
}