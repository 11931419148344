export type LoyaltyProgramType = "standard_auto" | "custom";
export type DaysLowercase = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

export const LOYALTY_PROGRAM_TYPES: { [loyaltyProgramType: string]: LoyaltyProgramType } = {
    AUTO: "standard_auto",
    CUSTOM: "custom"
}

export class LoyaltyData {
    programIds: string[] = [];
    programs: { [key: string]: LoyaltyProgram } = {};
    lastId: number = 0;
    defaultProgram: string;
}

export class LoyaltyProgram {
    loyaltyId: string;
    name: string;
    type: string;
    isDefault: boolean;
    autoValue?: number;
    showPrefix?: boolean;
    usePrefixSymbol?: boolean;
    prefix?: string;
    showSuffix?: boolean;
    useSuffixSymbol?: boolean;
    suffix?: string
    recurringMultiplierTimeOffset?: string;
    recurringMultiplier?: {
        [day in DaysLowercase]: {
            multiplier: number;
            start: string;
            end: string;
        }
    };
    constructor() {
        this.loyaltyId = "";
        this.name = "";
        this.type = LOYALTY_PROGRAM_TYPES.AUTO;
        this.autoValue = 10;
    }

}

export type LoyaltyPrograms = LoyaltyProgramAuto | LoyaltyProgramManual;

export interface LoyaltyProgramGeneral {
    loyaltyId: string;
    name: string;
    type: string;
}

export interface LoyaltyProgramAuto extends LoyaltyProgramGeneral {
    type: LoyaltyProgramType;
    autoValue: number;
    showPrefix?: undefined;
    usePrefixSymbol?: undefined;
    prefix?: undefined;
    showSuffix?: undefined;
    useSuffixSymbol?: undefined;
    suffix?: undefined;
}

export interface LoyaltyProgramManual extends LoyaltyProgramGeneral {
    type: LoyaltyProgramType;
    autoValue?: undefined;
    showPrefix: boolean;
    usePrefixSymbol: boolean;
    prefix: string;
    showSuffix: boolean;
    useSuffixSymbol: boolean;
    suffix: string
}