import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';
import { AuthConfig } from '@auth/auth.config';

@Injectable({
    providedIn: 'root'
})
export class RegistrationGuard implements CanActivate {

    protected guardName: string = 'Permission';

    constructor(
        private _auth: AuthService,
        private _router: Router
    ) { }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

        let isRegistering = this._auth.authChallengeName ? true : false;
        debug.log(`Guard - ${this.guardName}: ${isRegistering}`)
        if (!isRegistering) {
            return this._router.createUrlTree([AuthConfig.paths.login])
        }
        return true;

    }


}
