import { ClientsService } from '@admin/clients/clients.service';
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { Client } from '@shared/models/client';
import { Subject, takeUntil } from 'rxjs';

/**
 * The different styles of loaded clients:
 * 
 * 'active' - The client currently being manipulated in the interfaces.
 * 
 * 'native' - The client the user is currently logged into.
 */
export type ClientInterfaceType = "active" | "native";

@Directive({
    selector: '[appClientType]'
})
export class ClientTypeDirective implements OnInit, OnDestroy {

    @Input() targetClient: ClientInterfaceType = "active";
    @Input() allowClientTypes: string | string[] = null;
    @Input() disallowClientTypes: string | string[] = null;

    /** Handles the teardown of the rxjs subscriptions. */
    private _destroy$: Subject<void> = new Subject<void>();

    private _initialDisplayValue: string;

    constructor(
        private elementRef: ElementRef,
        private _auth: AuthService,
        private _clients: ClientsService
    ) { }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    ngOnInit(): void {

        switch (this.targetClient) {

            //The client being modified in the interface
            case "active":
                this._clients.clients.onActiveElementChange()
                    .pipe(takeUntil(this._destroy$))
                    .subscribe((client) => {
                        this.setElementVisibility(client.type)
                    })
                break;

            //The client the user is logged into
            case "native":
                this._auth.activeClient$
                    .pipe(takeUntil(this._destroy$))
                    .subscribe((client) => {
                        this.setElementVisibility(client.type)
                    })
                break;

            default:
                break;

        }

    }

    setElementVisibility(clientType: Client["type"]): void {
        const element = this.elementRef.nativeElement;
        if (!this._initialDisplayValue)
            this._initialDisplayValue = element?.style?.display || 'block';

        //Mosaic client can see all interfaces
        if ("superAdmin" === clientType) {
            this.showElement();
            return;
        }

        if (this.allowClientTypes) {


            if (Array.isArray(this.allowClientTypes)) {

                //Allowable by array
                if (this.allowClientTypes.includes(clientType)) {
                    this.showElement();
                } else {
                    this.hideElement();
                }

            } else {

                //Allowable by string
                if (this.allowClientTypes == clientType) {
                    this.showElement();
                } else {
                    this.hideElement();
                }

            }

        } else if (this.disallowClientTypes) {

            if (Array.isArray(this.disallowClientTypes)) {

                //Disallowable by array
                if (!this.disallowClientTypes.includes(clientType)) {
                    this.showElement();
                } else {
                    this.hideElement();
                }

            } else {

                //Disallowable by string
                if (this.disallowClientTypes != clientType) {
                    this.showElement();
                } else {
                    this.hideElement();
                }

            }

        }
    }

    showElement() {
        const element = this.elementRef.nativeElement;
        element.style.display = this._initialDisplayValue;
    }

    hideElement() {
        const element = this.elementRef.nativeElement;
        element.style.display = "none";
    }


}
