<mat-toolbar class="nav-admin">
    <mat-toolbar-row>
        <div class="nav-wrapper" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- <div class="brand">
        <img src="/assets/img/cc_logo.svg">
      </div> -->

            <div class="client-mirror" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon><i class="fad fa-clone"></i></mat-icon>
                <button mat-button [matMenuTriggerFor]="menu">
                    <!--! No icon because it either doesn't update or duplicates -->
                    <!--! Look into https://github.com/FortAwesome/angular-fontawesome -->
                    {{ activeClient?.clientData?.clientName || "Unknown Client" }}
                </button>
                <mat-menu #menu="matMenu" class="client-list">
                    <div class="search-field">
                        <span><i class="far fa-search"></i></span>
                        <input type="text" name="Client Search" [(ngModel)]="activeFilter" placeholder="Search..." (click)="$event.stopPropagation();" autocomplete="off">
                    </div>
                    <button mat-menu-item *ngFor=" let client of filteredClients();" (click)="selectClient(client.clientId)" [disabled]="clientLock" [class.selected]="client?.clientId === activeClient?.clientId">
                        <i class="margin-right far" [title]="client?.active ? 'Enabled' : 'Disabled'" [class.client-disabled]="!client?.active" [class.fa-car]="!client?.type || 'standard' === client?.type" [class.fa-car-building]="'proxy' === client?.type" [class.fa-users]="'management' === client?.type || 'superAdmin' === client?.type"></i>
                        {{ client?.clientData?.clientName || "Unknown Client" }}
                    </button>
                </mat-menu>
                <span class="client-id">{{ activeClient?.clientId || "Unknown Client Id" }}</span>
                <button mat-icon-button (click)="copyClientId()" title="Copy Client ID">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>

            <div class="nav-items">
                <button mat-button (click)="openDialog()" *ngIf="isMosaic">Add client +</button>
            </div>

        </div>

    </mat-toolbar-row>
</mat-toolbar>