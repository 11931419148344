import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AsyncStorage } from "@core/services/AsyncStorage";
import { ClientsService, Client } from "@admin/clients/clients.service";
import { APIResponse } from "@shared/types/APIResponse";
import { DebugService as debug } from "@core/services/debug.service";
import { LoyaltyReport } from "@core/data/loyaltyReport";
import { map } from "rxjs/operators";
import { AuthService } from "@core/services/auth/auth.service";

@Injectable()
export class LoyaltyLogService {
  private _loyalty: AsyncStorage<LoyaltyReport>;
  private _targetClientId: string;
  private _activeClientId: string;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {
    //Prepare moneris storage
    this._loyalty = new AsyncStorage(this._http);
    this._loyalty._setElementComparisonFunction(this._loyaltyComparison);
    this._loyalty._setUpdateFunction(this._updateLoyalty());

    this._clientsService.clients
      .onActiveElementChange()
      .subscribe((client: Client) => {
        this._targetClientId = client.clientId;
        this._loyalty.update();
      });
  }

  public get loyalty(): AsyncStorage<LoyaltyReport> {
    return this._loyalty;
  }
  public get activeClientId(): string {
    return this._activeClientId;
  }

  private _loyaltyComparison(a: LoyaltyReport, b: LoyaltyReport): boolean {
    //Compare the two
    return a != null && b != null
      ? a.trackerLoyaltyId === b.trackerLoyaltyId
      : false;
  }

  private _updateLoyalty(): () => Observable<LoyaltyReport[]> {
    let self = this;

    return () => {
      debug.log("Updating loyalty");

      return this._http
        .post("rewardReport/getLoyalty", {
          clientId: self._targetClientId || self._auth.activeClient?.clientId,
        })
        .pipe(
          map((data: APIResponse) => {
            this._activeClientId = this._targetClientId;
            //Convert the data
            let loyaltyData: LoyaltyReport[] = !data.errors.length
              ? data.data.Items
              : [];

            return loyaltyData;
          })
        );
    };
  }
}
