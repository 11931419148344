
import { distinctUntilChanged } from 'rxjs/operators';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StateInterface, StateLang, CountryStateList } from '@shared/data/countries';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { Subscriptions } from '@shared/lib/Subscriptions';
import { FeaturesService, Feature } from '@admin/clients/features.service';
import { Forms } from '@shared/lib/Forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { environment } from 'environments/environment';
import { ClientType } from '@core/data/client';

@Component({
    selector: 'app-add-client',
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ]
})
export class AddClientComponent implements OnInit {

    public clients?: Client[] = [];

    public siteURLSuffix: string = environment.ADMIN_URL;

    stateManager: StateManager = {
        location: {
            states: CountryStateList["USA"].list,
            stateOrProvince: CountryStateList["USA"].lang,
            lastCountry: "USA"
        },
        primary: {
            states: CountryStateList["USA"].list,
            stateOrProvince: CountryStateList["USA"].lang,
            lastCountry: "USA"
        },
        billing: {
            states: CountryStateList["USA"].list,
            stateOrProvince: CountryStateList["USA"].lang,
            lastCountry: "USA"
        }
    }

    private _subscriptions: Subscription[] = [];

    private _cachedBillingValues: Contact;

    public clientTypeForm: FormGroup;
    public managedClientsForm: FormGroup;
    public locationForm: FormGroup;
    public contactForm: FormGroup;
    public moduleForm: FormGroup;
    public features: { [key: string]: Feature } = {};


    constructor(
        public dialogRef: MatDialogRef<AddClientComponent>,
        private _formBuilder: FormBuilder,
        private _clientsService: ClientsService,
        private _featuresService: FeaturesService,
        @Inject(MAT_DIALOG_DATA) public dialogData?: { clients: Client[] }
    ) {
        if (dialogData?.clients)
            this.clients = dialogData.clients.filter((client) => !client.isCwc);
    }

    ngOnInit() {
        this._createForms();
        this._getData();
    }

    ngOnDestroy() {
        Subscriptions.unsubscribe(this._subscriptions);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    private _getData() {

        var featureSubscription = this._featuresService.features.onUpdate()
            .subscribe((features: Feature[]) => {

                //Add current features to ui
                for (let feature of features) {
                    if (!feature.available) continue;
                    let type: "optional" | "mandatory" = feature.data.optional ? "optional" : "mandatory";
                    this._newFeature(type, feature.internalName);
                    this.features[feature.internalName] = feature;
                }

                //Unsubscribe from the feature subscription
                if (featureSubscription) featureSubscription.unsubscribe();

            });
        this._subscriptions.push(featureSubscription);
    }

    private _newFeature(type: "mandatory" | "optional", name: string) {
        if (!this.moduleForm) return;
        let features: FormGroup = <FormGroup>this.moduleForm.controls[type];
        let mandatory = "mandatory" == type ? true : false;
        features.addControl(name, this._formBuilder.group({
            enabled: new FormControl({ value: mandatory, disabled: mandatory }, Validators.compose([Validators.required])),
            //! This is "" was changed to 0 as a temporary fix to remove the necessity of the feature form.
            flat: new FormControl(0, Validators.compose([Validators.required])),
            monthly: new FormControl(0, Validators.compose([Validators.required]))
        }));
    }

    private _createForms() {


        //Prepare the client type form
        let clientTypeForm: FormGroup = new FormGroup({
            clientType: new FormControl("", Validators.compose([Validators.required]))
        })
        this.clientTypeForm = clientTypeForm;


        //The validator that flags a control as required for certain client types
        let clientTypeValidator = (requiredTypes: ClientType[], validator: ValidatorFn) => {
            return (formGroup: FormControl) => {
                let clientType = clientTypeForm.get("clientType").value;
                if (requiredTypes.includes(clientType))
                    return validator(formGroup)
                else
                    return null;
            }
        }



        let superEmail1: FormControl = new FormControl("", clientTypeValidator(["standard", "management"], Validators.compose([Validators.required, Validators.email])));
        let superEmail2: FormControl = new FormControl("");
        superEmail2.setValidators(clientTypeValidator(["standard", "management"], Validators.compose([Validators.required, Validators.email, FieldValidation.matchFields(superEmail1, superEmail2)])));



        this.managedClientsForm = new FormGroup({
            managedClients: new FormControl("", clientTypeValidator(["management"], Validators.compose([Validators.required])))
        })

        this.locationForm = this._formBuilder.group({
            clientName: new FormControl("", Validators.compose([Validators.required])),
            corporateId: new FormControl("", Validators.compose([Validators.required])),
            address1: new FormControl("", Validators.compose([Validators.required])),
            address2: new FormControl(""),
            city: new FormControl("", Validators.compose([Validators.required])),
            state: new FormControl("", Validators.compose([Validators.required])),
            country: new FormControl("", Validators.compose([Validators.required])),
            code: new FormControl("", Validators.compose([Validators.required])),
            url: new FormControl("", clientTypeValidator(["standard", "management"], Validators.compose([Validators.required]))),
            superAdminEmail1: superEmail1,
            superAdminEmail2: superEmail2
        });

        //Create the form
        this.contactForm = this._formBuilder.group({
            primary: this._formBuilder.group({
                firstName: new FormControl("", Validators.compose([Validators.required])),
                lastName: new FormControl("", Validators.compose([Validators.required])),
                phoneNumber: new FormControl("", Validators.compose([Validators.required])),
                email: new FormControl("", Validators.compose([Validators.required, Validators.email])),
                title: new FormControl("", Validators.compose([Validators.required])),
                commPref: new FormControl("", Validators.compose([Validators.required])),
                address1: new FormControl("", Validators.compose([Validators.required])),
                address2: new FormControl(""),
                city: new FormControl("", Validators.compose([Validators.required])),
                state: new FormControl("", Validators.compose([Validators.required])),
                country: new FormControl("", Validators.compose([Validators.required])),
                code: new FormControl("", Validators.compose([Validators.required]))
            }),
            billing: this._formBuilder.group({
                firstName: new FormControl("", Validators.compose([Validators.required])),
                lastName: new FormControl("", Validators.compose([Validators.required])),
                phoneNumber: new FormControl("", Validators.compose([Validators.required])),
                email: new FormControl("", Validators.compose([Validators.required, Validators.email])),
                title: new FormControl("", Validators.compose([Validators.required])),
                commPref: new FormControl("", Validators.compose([Validators.required])),
                address1: new FormControl("", Validators.compose([Validators.required])),
                address2: new FormControl(""),
                city: new FormControl("", Validators.compose([Validators.required])),
                state: new FormControl("", Validators.compose([Validators.required])),
                country: new FormControl("", Validators.compose([Validators.required])),
                code: new FormControl("", Validators.compose([Validators.required]))
            }),
            billingSameAsPrimary: new FormControl(false, Validators.compose([Validators.required]))
        });

        this.moduleForm = this._formBuilder.group({
            mandatory: this._formBuilder.group({}),
            optional: this._formBuilder.group({})
        });

        this._subscriptions.push(this.locationForm.valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(() => {
                this._updateStates("location", this.locationForm);
            }));

        this._subscriptions.push(superEmail1.valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(() => {
                if (superEmail2.touched) superEmail2.updateValueAndValidity();
            }));

        this._subscriptions.push(this.contactForm.controls["primary"].valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(() => {
                this._updateBillingContact();
                this._updateStates("primary", <FormGroup>this.contactForm.controls["primary"]);
            }));

        this._subscriptions.push(this.contactForm.controls["billing"].valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(() => {
                this._updateStates("billing", <FormGroup>this.contactForm.controls["billing"]);
            }));

        this._subscriptions.push(this.contactForm.controls["billingSameAsPrimary"].valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(() => {
                this._toggleBilling(!this.contactForm.value["billingSameAsPrimary"]);
            }));

    }

    private _updateStates(formName: "location" | "primary" | "billing", form: FormGroup) {
        //Change the state list
        let country = form.value["country"]
        if (!country) return
        let formState: StateOptions = this.stateManager[formName]
        if (formState.lastCountry != country) {
            formState.lastCountry = country;
            form.patchValue({ state: "" }, { onlySelf: true, emitEvent: false });

            formState.states = CountryStateList[country].list
            formState.stateOrProvince = CountryStateList[country].lang
        }
    }

    private _updateBillingContact() {
        if (this.contactForm.controls["billing"].disabled) {
            this.contactForm.controls["billing"].patchValue(this.contactForm.controls["primary"].value, { onlySelf: false, emitEvent: false })
        }
    }

    private _toggleBilling(disabledBilling: boolean) {
        if (disabledBilling !== true && disabledBilling !== false) return;
        if (disabledBilling) {
            this._cachedBillingValues = this.contactForm.controls["billing"].value;
            this.contactForm.controls["billing"].disable({ onlySelf: false, emitEvent: false });
            this.contactForm.controls["billing"].patchValue(this.contactForm.controls["primary"].value, { onlySelf: false, emitEvent: false })
        } else {

            this.contactForm.controls["billing"].enable({ onlySelf: false, emitEvent: false });
            if (this._cachedBillingValues)
                this.contactForm.controls["billing"].patchValue(this._cachedBillingValues, { onlySelf: false, emitEvent: false })
        }

    }

    public getControlNames(control: AbstractControl): string[] {
        let controls = (<FormGroup>control).controls
        let arr: string[] = [];
        for (let key in controls) {
            arr.push(key);
        }
        return arr
    }

    public getControl(control: AbstractControl, controlName: string): FormGroup {
        return <FormGroup>((<FormGroup>control).controls[controlName]);
    }

    public save() {
        if (Forms.isInvalid(this.clientTypeForm)) return;
        if (Forms.isInvalid(this.managedClientsForm)) return;
        if (Forms.isInvalid(this.locationForm)) return;
        if (Forms.isInvalid(this.contactForm)) return;
        if (Forms.isInvalid(this.moduleForm)) return;

        let client: Client = this._copyValues();
        this._clientsService.clients.add(client);
        this.closeDialog();
    }

    private _copyValues(): Client {
        let client: Client = new Client();

        //Active
        client.active = client.clientData.active = client.clientData.data.active = client.appData.active = false;

        //---------------------------------
        //-Client Type  Form---------------
        //---------------------------------

        let clientTypeForm: { clientType: ClientType } = this.clientTypeForm.value;
        client.type = clientTypeForm.clientType;

        //---------------------------------
        //-Managed Clients Form------------
        //---------------------------------

        let managedClientsForm: { managedClients: string[] } = this.managedClientsForm.value;
        if (managedClientsForm.managedClients.length)
            client.managedClients = managedClientsForm.managedClients;

        //---------------------------------
        //-Location Form-------------------
        //---------------------------------

        let locationValues: LocationForm = this.locationForm.value;

        //Super admin email
        if (locationValues.superAdminEmail1.length)
            client.superAdminEmail = locationValues.superAdminEmail1;

        //URL 
        client.url = locationValues.url.toLowerCase();

        //Location
        client.clientData.clientName = locationValues.clientName;
        client.clientData.clientId = locationValues.corporateId;
        client.clientData.data.address.line1 = locationValues.address1;
        if (locationValues.address2)
            client.clientData.data.address.line2 = locationValues.address2;
        client.clientData.data.address.city = locationValues.city;
        client.clientData.data.address.country = locationValues.country;
        client.clientData.data.address.state = locationValues.state;
        client.clientData.data.address.code = locationValues.code;

        //CWC Representative
        client.clientData.data.accountRep.firstName = "[deprecated]";
        client.clientData.data.accountRep.lastName = "[deprecated]";

        //---------------------------------
        //-Contact Form--------------------
        //---------------------------------

        let contactValues: ContactForm = this.contactForm.value;

        //Primary contact
        client.clientData.data.primaryContact.firstName = contactValues.primary.firstName;
        client.clientData.data.primaryContact.lastName = contactValues.primary.lastName;
        client.clientData.data.primaryContact.title = contactValues.primary.title;
        client.clientData.data.primaryContact.phone = contactValues.primary.phoneNumber;
        client.clientData.data.primaryContact.email = contactValues.primary.email;
        client.clientData.data.primaryContact.commPref = contactValues.primary.commPref;
        client.clientData.data.primaryContact.address.line1 = contactValues.primary.address1;
        if (contactValues.primary.address2)
            client.clientData.data.primaryContact.address.line2 = contactValues.primary.address2;
        client.clientData.data.primaryContact.address.city = contactValues.primary.city;
        client.clientData.data.primaryContact.address.state = contactValues.primary.state;
        client.clientData.data.primaryContact.address.country = contactValues.primary.country;
        client.clientData.data.primaryContact.address.code = contactValues.primary.code;


        //Billing contact
        let copy = client.clientData.data.primaryAsBilling = contactValues.billingSameAsPrimary;

        //Copies from the primary or billing depending on if we're supposed to copy
        let contact: "billing" | "primary" = true == copy ? "primary" : "billing";
        client.clientData.data.billingContact.firstName = contactValues[contact].firstName;
        client.clientData.data.billingContact.lastName = contactValues[contact].lastName;
        client.clientData.data.billingContact.title = contactValues[contact].title;
        client.clientData.data.billingContact.phone = contactValues[contact].phoneNumber;
        client.clientData.data.billingContact.email = contactValues[contact].email;
        client.clientData.data.billingContact.commPref = contactValues[contact].phoneNumber;
        client.clientData.data.billingContact.address.line1 = contactValues[contact].address1;
        if (contactValues[contact].address2)
            client.clientData.data.billingContact.address.line2 = contactValues[contact].address2;
        client.clientData.data.billingContact.address.city = contactValues[contact].city;
        client.clientData.data.billingContact.address.state = contactValues[contact].state;
        client.clientData.data.billingContact.address.country = contactValues[contact].country;
        client.clientData.data.billingContact.address.code = contactValues[contact].code;

        //---------------------------------
        //-Module Form---------------------
        //---------------------------------
        let moduleValues: ModuleForm = this.moduleForm.value;


        delete client.appData.features
        client.appData.features = {};

        //Mandatory
        for (let featureId in moduleValues.mandatory) {
            let feature: Feature = this.features[featureId];
            client.appData.features[feature.internalName] = {
                name: feature.name,
                enabled: moduleValues.mandatory[featureId].enabled || false,
                defaultCost: -1,
                negotiatedCost: Number(moduleValues.mandatory[featureId].flat) || 0,
                recurringCost: -1,
                negotiatedRecurringCost: Number(moduleValues.mandatory[featureId].monthly) || 0,
                recurranceFrequency: "monthly",
            }
        }

        //Optional
        for (let featureId in moduleValues.optional) {
            let feature: Feature = this.features[featureId];
            client.appData.features[feature.internalName] = {
                name: feature.name,
                enabled: moduleValues.optional[featureId].enabled || false,
                defaultCost: -1,
                negotiatedCost: Number(moduleValues.optional[featureId].flat) || 0,
                recurringCost: -1,
                negotiatedRecurringCost: Number(moduleValues.optional[featureId].monthly) || 0,
                recurranceFrequency: "monthly",
            }
        }

        return client;
    }

}

interface LocationForm {
    clientName: string;
    corporateId: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    code: string;
    url: string;
    superAdminEmail1: string;
    superAdminEmail2: string;
}

interface ContactForm {
    primary: Contact;
    billing?: Contact
    billingSameAsPrimary: boolean;
}

interface ModuleForm {
    mandatory: { [key: string]: CWCModule };
    optional: { [key: string]: CWCModule };
}

interface Contact {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    title: string;
    commPref: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    code: string;
}

interface StateManager {
    location: StateOptions;
    primary: StateOptions;
    billing: StateOptions;
}

interface StateOptions {
    states: StateInterface[],
    stateOrProvince: StateLang,
    lastCountry: string
}

interface CWCModule {
    enabled: boolean;
    flat: number;
    monthly: number;
}

class FieldValidation {
    static matchFields(field1: AbstractControl, field2: AbstractControl): ValidatorFn {
        return (): ValidationErrors => {
            if (field1.value != field2.value) {
                return { MatchField: true }
            } else {
                return null
            }
        }
    }
}