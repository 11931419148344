
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { DirectSalesData, Sale, SalesData, SaleLocationData } from "@core/data/sales";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { Objects } from '@shared/lib/Objects';
import { AuthService } from '@core/services/auth/auth.service';

export { AsyncStorage, DirectSalesData, Sale, SalesData, SaleLocationData }

type SalesObject = { [saleId: string]: Sale };

const SaleIdPrefix = "directSale";

@Injectable()
export class DirectSalesService {

    public saleIdPrefix = SaleIdPrefix
    private _directSales: AsyncStorage<Sale>;
    private _activeClientId: string;
    private _targetClientId: string;
    private _lastUsedId: number = 0;
    private _cachedSales: SalesObject;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare location storage
        this._directSales = new AsyncStorage(this._http);
        this._directSales._setAddFunction(this._saveSales());
        this._directSales._setElementComparisonFunction(this._saleComparison);
        this._directSales._setSaveActiveElementFunction(this._saveSales());
        this._directSales._setUpdateFunction(this._updateSales());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._directSales.update();
            });

        this._directSales.onUpdate().subscribe((sales: Sale[]) => {
            this._cacheSales(sales);
        })
    }

    public get sales() { return this._directSales };
    public get saleIds() { return this._directSales.data.value.map((sale: Sale) => sale.saleId) }
    public get nextId(): string { return this._lastUsedId++, this.saleIdPrefix + this._lastUsedId; };
    public get activeClientId(): string { return this._activeClientId };

    private _salesToObject(sales: Sale[]): SalesObject {
        let salesObject: SalesObject = {};
        for (let sale of sales) {
            salesObject[sale.saleId] = sale;
        }
        return salesObject;
    }

    private _cacheSales(sales: Sale[]): void {
        this._cachedSales = this._salesToObject(Objects.copy(sales));
    }

    private _saleComparison(a: Sale, b: Sale): boolean {

        //Compare the two
        return a && b ? a.saleId === b.saleId : false;

    }

    private _saveSales(): (garbage: any, options?: any) => Observable<any> {

        let self = this;

        return (garbage: any, options?: any): Observable<any> => {

            let getUpdatedSales: () => Promise<{ data: SalesObject, lastId: number, isProxy: boolean }> = async () => {
                try {

                    let clientId: string = self._activeClientId || self._auth.activeClient?.clientId;

                    //Load the current sales
                    let getResponse: APIResponse = <APIResponse>await this._http.post("directSales/get", { clientId: clientId }).toPromise();

                    //Convert the data
                    let directSalesData: DirectSalesData = getResponse.data;

                    //Ensure configuration data matches
                    if (!Object.keys(directSalesData).length) directSalesData = new DirectSalesData();
                    if (clientId != (directSalesData.clientId || clientId)) return Promise.reject(new Error("Client id mismatch."));

                    //Convert the sales into an array
                    let directSales: Sale[] = [];
                    for (let i in directSalesData.salesData.data)
                        directSales.push(directSalesData.salesData.data[i]);

                    //Convert all sales to the sale object
                    let serverSales: SalesObject = this._salesToObject(directSales);
                    let sales: SalesObject = this._salesToObject(self._directSales.data.value);
                    let changes: { additions?: any, deletions?: any, updates?: any } = Objects.compare(this._cachedSales, sales);

                    //Update server sale values
                    //This may re-add deleted sales
                    let saleUpdates: any = Objects.merge(changes.additions || {}, changes.updates || {});
                    for (let saleId in saleUpdates) {
                        if (serverSales[saleId])
                            serverSales[saleId] = Objects.merge(serverSales[saleId] || {}, saleUpdates[saleId]);
                        else
                            serverSales[saleId] = Objects.merge(sales[saleId] || {}, saleUpdates[saleId]);
                    }

                    //Delete server sale values
                    if (changes.deletions)
                        serverSales = Objects.subtract(serverSales, changes.deletions)

                    //Figure out the last used id
                    let lastId = Number(Object.keys(serverSales).sort().pop().replace(this.saleIdPrefix, ""));
                    lastId = this._lastUsedId > lastId ? this._lastUsedId : lastId;

                    let isProxy = directSalesData.isProxy ? directSalesData.isProxy : false

                    debug.log("Local:", sales);
                    debug.log("Changes:", changes);
                    debug.log("Remote merged:", serverSales)
                    debug.log("Last used id number:", lastId);

                    return Promise.resolve({
                        data: serverSales,
                        lastId: lastId,
                        isProxy: isProxy
                    });
                } catch (e) {
                    return Promise.reject(e);
                }
            }

            //Update or add the item
            return new Observable(observer => {
                getUpdatedSales()
                    .then((saleData) => {
                        //Prepare the data to send
                        let requestData: DirectSalesData = {
                            clientId: self._activeClientId || self._auth.activeClient?.clientId,
                            salesData: saleData,
                            isProxy: saleData.isProxy
                        };

                        this._http.post("directSales/update", requestData).toPromise()
                            .then((data: APIResponse) => {
                                if (0 == Object.keys(data.data).length || data.errors.length)
                                    //Add if we get an error
                                    this._http.post("directSales/add", requestData).toPromise()
                                        .then((data: APIResponse) => { observer.next(data); })
                                        .catch(error => { observer.next(); })
                                else
                                    observer.next(data);
                            })
                            .catch(error => {
                                //Add if we get an error
                                this._http.post("directSales/add", requestData).toPromise()
                                    .then((data: APIResponse) => { observer.next(data); })
                                    .catch(error => { observer.next(); })

                            })
                    })
                    .catch((e) => {
                        debug.error(e);
                        observer.error(e);
                    })
            })
        }

    }

    private _updateSales(): () => Observable<Sale[]> {
        let self = this;

        return () => {
            debug.log("Updating direct sales");

            return this._http.post("directSales/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    this._activeClientId = this._targetClientId;

                    //Convert the data
                    let directSalesData: DirectSalesData = data.data;

                    //Create a new object if nothing was found
                    if (!Object.keys(directSalesData).length) directSalesData = new DirectSalesData();

                    //Convert the sales into an array
                    let sales: Sale[] = [];
                    for (let i in directSalesData.salesData.data) {

                        sales.push(directSalesData.salesData.data[i]);

                    }

                    //Store the last used id
                    this._lastUsedId = directSalesData.salesData.lastId;

                    return sales;
                }));
        }
    }

}
