import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthConfig } from '@auth/auth.config';
import { map, Observable } from 'rxjs';
import { LoggedInGuard } from '../logged-in/logged-in.guard';
import { DebugService as debug } from "@core/services/debug.service";

@Injectable({
    providedIn: 'root'
})
export class LoggedOutGuard implements CanActivate {

    protected guardName: string = 'Logged Out';

    constructor(
        private _loggedInGuard: LoggedInGuard,
        private _router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._loggedInGuard.canActivate(route, state)
            .pipe(map((isLoggedIn: boolean | UrlTree) => {
                let isLoggedOut = "boolean" != typeof isLoggedIn ? true : false;
                debug.log(`Guard - ${this.guardName}: ${isLoggedOut}`)
                if (!isLoggedOut)
                    return this._router.createUrlTree([AuthConfig.paths.home])
                return true;
            }))
    }

}
