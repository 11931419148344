import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout'; //https://github.com/angular/flex-layout/wiki/ObservableMedia

// SERVICES
import { AuthService } from '@core/services/auth/auth.service';

// COMPONENTS
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Subscriptions } from '@shared/lib/Subscriptions';
import { UpdateService } from '@core/services/update/update.service';

@Component({
    selector: 'app-scaffold',
    templateUrl: './scaffold.component.html',
    styleUrls: ['./scaffold.component.scss']
})
export class ScaffoldComponent implements OnInit, OnDestroy {

    navMode = 'side';
    state = true;
    isMobile = false;
    hideBanner = false;

    @ViewChild('sidenav', { static: false }) sidenav;

    // User States
    isMosaic: boolean = false;
    isManagementClient: boolean = false;
    isLoggedIn: boolean = false;
    isInitialized: boolean = false;
    isInitializationError: boolean = false;
    isNewVersion: boolean = false;

    showLoader: boolean = false;

    private _subscriptions: Subscription[] = []

    constructor(
        public media: MediaObserver,
        private auth: AuthService,
        public router: Router,
        private _update: UpdateService
    ) { }

    ngOnInit() {

        this.sidebarToggle();
        this._subscriptions.push(this.auth.activeUserPermissions$
            .subscribe(() => {
                this.isMosaic = this.auth.isMosaic;
                this.isManagementClient = this.auth.isManagementClient;
                this.isLoggedIn = this.auth.isLoggedIn;
            })
        )

        //Check if there are new versions
        this._subscriptions.push(this._update.isUpdateAvailable$
            .subscribe((isNewVersion) => {
                this.isNewVersion = isNewVersion;
            })
        )

        //Check initialization status
        this._subscriptions.push(this.auth.hasInitializedGuardExecuted$
            .subscribe((isInitialized) => {
                this.isInitialized = isInitialized;
            })
        )

        
        //Delay loader such that elements are not spamming the page
        setTimeout(() => {
            if (!this.isInitialized) this.showLoader = true;
        }, 1000)

        //Initialization has failed
        setTimeout(() => {
            if (!this.isInitialized) this.isInitializationError = true;
        }, 30000)
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribe(this._subscriptions)
    }

    /**
     * sidebarToggle
     * Toggles the sidebar when hitting responsive mobile breakpoints
     * @memberof ScaffoldComponent
     */

    sidebarToggle() {
        let subscriptionMedia: Subscription = this.media.media$.subscribe({
            next: (change: MediaChange) => {
                if (change.mqAlias === 'xs' || change.mqAlias === 'sm' || change.mqAlias === 'md') {
                    this.navMode = 'push';
                    this.state = false;
                    this.isMobile = true;
                } else {
                    this.navMode = 'side';
                    this.state = true;
                    this.isMobile = false;
                }
            }
        });
        this._subscriptions.push(subscriptionMedia);
    }

    hideNavigation(): boolean {
        if ("/redirect" == this.router.url) return true;
        return false;
    }
}
