<div class="device" *ngIf="device">
  <div class="device-blocked-status" [class]="device?.blockedStatus">
    <ng-container *ngIf="'allowed' == device?.blockedStatus">Allowed</ng-container>
    <ng-container *ngIf="'blocked' == device?.blockedStatus">Blocked</ng-container>
    <ng-container *ngIf="'inactive' == device?.blockedStatus">Inactive</ng-container>
  </div>
  <h3>

    <!-- Device Icon -->
    <span class="device-icon">
      <ng-container *ngIf="isAndroidDevice(device?.osType)">
        <i class="fab fa-android"></i>
      </ng-container>
      <ng-container *ngIf="isAppleDevice(device?.osType)">
        <i class="fab fa-apple"></i>
      </ng-container>
      <ng-container *ngIf="isUnknownDevice(device?.osType)">
        <i class="fab fa-mobile"></i>
      </ng-container>
    </span>

    <!-- Device Name -->
    {{device?.device}}

    <!-- Last Seen -->
    <div class="sub-header" *ngIf="device?.accessedOn">Last seen {{ toFromNowDate(device?.accessedOn) }}</div>
  </h3>

  <!-- OS Details -->
  <div>
    <ng-container *ngIf="device?.osType">{{ device?.osType }} </ng-container>
    <ng-container *ngIf="device?.osVersion">{{ device?.osVersion }}</ng-container>
  </div>
  <div *ngIf="device?.appVersion">
    App version - {{ device?.appVersion }}
  </div>
  <div *ngIf="device?.cellularConnection">
    <ng-container *ngIf="'enable' == device?.cellularConnection || 'enabled' == device?.cellularConnection">
      <i class="fa-solid fa-signal" title="Mobile Data"></i>
      Mobile data on
    </ng-container>
    <ng-container *ngIf="'disable' == device?.cellularConnection || 'disabled' == device?.cellularConnection">
      <i class="fa-solid fa-signal-slash" title="Mobile Data"></i>
      Mobile data off
    </ng-container>
  </div>

  <button mat-stroked-button *ngIf="allowAction" [color]="getColour()" (click)="deviceAction()" class="device-action" [disabled]="isDisabled">
    <ng-container *ngIf="'allowed' == device?.blockedStatus || 'inactive' == device?.blockedStatus">Block Device</ng-container>
    <ng-container *ngIf="'blocked' == device?.blockedStatus">Unblock Device</ng-container>
  </button>
</div>
