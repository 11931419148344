import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { User } from '@shared/models/user';
import { Subject, takeUntil } from 'rxjs';

@Directive({
    selector: '[appUserRole]'
})
export class UserRoleDirective implements OnInit, OnDestroy {

    @Input() minimumRole: User["role"] = "unknown"

    /** Handles the teardown of the rxjs subscriptions. */
    private _destroy$: Subject<void> = new Subject<void>();

    private _initialDisplayValue: string;

    constructor(
        private elementRef: ElementRef,
        private _auth: AuthService
    ) { }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    ngOnInit(): void {
        this._auth.activeUser$
            .pipe(takeUntil(this._destroy$))
            .subscribe((user) => {
                this.setElementVisibility()
            })
    }

    setElementVisibility(): void {
        const element = this.elementRef.nativeElement;
        if (!this._initialDisplayValue)
            this._initialDisplayValue = element?.style?.display || 'block';

        if (this._auth.hasRole(this.minimumRole)) {
            this.showElement();
        } else {
            this.hideElement();
        }

    }

    showElement() {
        const element = this.elementRef.nativeElement;
        element.style.display = this._initialDisplayValue;
    }

    hideElement() {
        const element = this.elementRef.nativeElement;
        element.style.display = "none";
    }


}
