export let DayOrder: string[] = ["sat", "sun", "mon", "tue", "wed", "thu", "fri"];

export class Location {
    locationId?: string;
    clientId: string;
    timeStamp: Date;
    lat: number;
    lng: number;
    data: LocationData;
    sharedWith: Array<any>;
    enabled: boolean;

    constructor(coords: { lat: number, lng: number }) {

        this.timeStamp = new Date();
        this.lat = coords.lat;
        this.lng = coords.lng;
        this.data = new LocationData();

        this.data.coords = new Coords(coords);
        this.clientId = "PLACE_HOLDER_ID";
        this.sharedWith = [];
        this.enabled = false;
    }

}

export class LocationData {
    name: string;
    address: string;
    bannerImage?: string;
    city: string;
    corporateLocationId?: string;
    prov: string;
    postalCode: string;
    country: string;
    servicePhone?: string;
    adminPhone?: string;
    serviceEmail?: string;
    adminEmail?: string;
    website?: string;
    coords?: Coords;
    hours?: Hours;
    timezone?: {
        abbreviation?: string;
        countryCode?: string;
        countryName?: string;
        daylightSavingTime?: boolean;
        gmtOffset?: number;
        zoneEnd?: number;
        zoneName?: string;
        zoneStart?: number;
    }
}


export class Coords {
    lat: string;
    lng: string;
    proxLat?: string;
    proxLng?: string;
    radius?: string;
    unit?: "feet" | "metres";

    constructor(coords?) {
        this.proxLat = "";
        this.proxLng = "";
        this.radius = "";
        this.unit = "feet";
    }
}

export class Hours {
    order: Array<string>;
    mon: Day;
    tue: Day;
    wed: Day;
    thu: Day;
    fri: Day;
    sat: Day;
    sun: Day;

    constructor() {
        this.order = DayOrder;
        this.mon = { day: "Monday", is24h: false, isClosed: false, prop: 'mon' };
        this.tue = { day: "Tuesday", is24h: false, isClosed: false, prop: 'tue' };
        this.wed = { day: "Wednesday", is24h: false, isClosed: false, prop: 'wed' };
        this.thu = { day: "Thursday", is24h: false, isClosed: false, prop: 'thu' };
        this.fri = { day: "Friday", is24h: false, isClosed: false, prop: 'fri' };
        this.sat = { day: "Saturday", is24h: false, isClosed: false, prop: 'sat' };
        this.sun = { day: "Sunday", is24h: false, isClosed: false, prop: 'sun' };
    }
}

export interface Day {
    day: string;
    is24h: boolean,
    isClosed: boolean,
    opens?: string;
    closes?: string;
    prop: string;
}

