import { Component, Input, OnInit } from '@angular/core';
import { ReportTransaction } from '@core/data/reports';
import { DebugService as debug } from '@core/services/debug.service';
import { Objects } from '@shared/lib/Objects';
import * as moment from 'moment';
import { ApexAxisChartSeries, ApexOptions } from "ng-apexcharts";

@Component({
    selector: 'transaction-benchmark',
    templateUrl: './transaction-benchmark.component.html',
    styleUrls: ['./transaction-benchmark.component.scss']
})
export class TransactionBenchmarkComponent implements OnInit {

    /** The report transaction containing the details for the chart. */
    @Input() public transaction: ReportTransaction;

    /** The options for the chart */
    public chartOptions: Partial<ApexOptions>;

    /** The starting timestamp for the chart timespan. */
    public minTimestamp: number = Infinity;

    /** The font style for the chart. */
    public fontStyle: string = "Ubuntu, sans-serif";

    /** The available font sizes for the chart. */
    public fontSize: Record<keyof typeof FontSize, string> = {
        Small: "12px",
        Medium: "16px",
        Large: "20px"
    };

    /** The available colours for the chart. */
    public colour: Record<keyof typeof FontSize, string> = {
        Small: "12px",
        Medium: "16px",
        Large: "20px"
    };

    constructor() { }

    ngOnInit(): void {
        this.chartOptions = this.getChartOptions();
    }

    public getChartOptions(): Partial<ApexOptions> {
        let series: ApexAxisChartSeries = this.getBenchmarkSeries(this.transaction)
        let chartHeight: number = 30 * series[0].data.length;
        return {
            series: series,
            chart: {
                width: "100%",
                height: chartHeight,
                parentHeightOffset: 0,
                type: "rangeBar",
                animations: { enabled: true },
                toolbar: { show: false },
                zoom: { enabled: false },
                selection: { enabled: false },
                brush: { enabled: false },
                redrawOnWindowResize: true,
                redrawOnParentResize: true
            },
            plotOptions: {
                area: {
                    "fillTo": "origin"
                },
                bar: {
                    horizontal: true,
                    distributed: true,
                    dataLabels: {
                        hideOverflowingLabels: true
                    },

                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    let a = moment(val[0]);
                    let b = moment(val[1]);
                    let diff = b.diff(a, "ms");
                    diff = diff / 1000;
                    return `${diff.toFixed(2)}s`;
                },

                style: {
                    colors: ["#f3f4f5", "#fff"],
                    fontSize: this.fontSize.Small,
                    fontFamily: this.fontStyle,
                    fontWeight: 400
                }
            },
            xaxis: {
                type: "datetime",
                labels: {
                    show: false
                }
            },
            yaxis: {
                show: true,
                labels: {
                    style: {
                        fontSize: this.fontSize.Medium,
                        fontFamily: this.fontStyle,
                    }
                },
                showForNullSeries: false
            },
            grid: {
                show: false,
                row: {
                    colors: ["#f3f4f5", "#fff"],
                    opacity: 1
                },
                padding: {
                    left: 50,
                    bottom: -10,
                    top: -28,
                    right: 0
                }
            },
            noData: {},
            tooltip: {
                enabled: true,
                x: {
                    formatter: (value, options) => {
                        if ("string" === typeof value) return value;
                        let a = moment(this.minTimestamp);
                        let b = moment(value);
                        let diff = b.diff(a, "ms");
                        diff = diff / 1000;
                        return `${diff.toFixed(2)}s`
                    }
                },
                style: {
                    fontSize: this.fontSize.Medium,
                    fontFamily: this.fontStyle
                }
            }
        }
    }

    /**
     * Generated the data series for the time chart.
     * @param transaction The report transaction containing the benchmark/duration details.
     */
    public getBenchmarkSeries(transaction: ReportTransaction): ApexAxisChartSeries {
        /** The supported internal benchmark ids in the order to be displayed (top down). */
        const benchmarkOrder: string[] = ["transaction", "databaseRead", "payment", "subscription", "codeRetrieval", "codeSaving"]

        let benchmarkData = [];
        if (transaction && Objects.isObject(transaction.durationDetails)) {
            for (let key of benchmarkOrder) {

                //Skip if the term is not supported
                if (!transaction.durationDetails[key]) continue;

                //Add to the series
                benchmarkData.push({
                    x: this.getBenchmarkText(key, transaction.transactionType),
                    y: [
                        transaction.durationDetails[key].startedOn,
                        transaction.durationDetails[key].endedOn
                    ],
                    fillColor: "failure" === transaction.durationDetails[key].status ? ChartColor.Failure : ChartColor.Success
                })

                //Update the start timestamp
                this.minTimestamp = Math.min(this.minTimestamp, transaction.durationDetails[key].startedOn)

            }
        }

        // Return the series
        return [{ data: benchmarkData }]
    }

    /**
     * Converts internal benchmark ids into customer friendly text.
     * @param internalId The internal benchmark/duration id.
     * @param transactionType The transactionType for the transaction.
     */
    public getBenchmarkText(internalId: string, transactionType: string): string {

        /** The conversion object of ids to customer friendly text. */
        const Benchmark_Labels = {
            transaction: "Transaction",
            databaseRead: "Loading",
            payment: {
                default: "Payment Processing"
            },
            codeRetrieval: {
                default: "Wash Code Request",
                subscription: "Sub Generation",
                subscriptionUpgrade: "Sub Generation"
            },
            codeSaving: {
                default: "Wash Code Saving",
                subscription: "Sub Saving",
                subscriptionUpgrade: "Sub Saving"
            },
            subscription: "Subscription"
        }
        // Return the is an easy conversion
        if ("string" === typeof Benchmark_Labels[internalId]) return Benchmark_Labels[internalId];

        // Return the transaction specific text or the default text
        if (Objects.isObject(Benchmark_Labels[internalId])) {
            if ("string" === typeof Benchmark_Labels[internalId][transactionType]) {
                return Benchmark_Labels[internalId][transactionType]
            } else {
                return Benchmark_Labels[internalId]["default"]
            }
        }

        // Return the input if there is no conversion available
        return internalId
    }

}

enum ChartColor {
    Success = "#2196f3",
    Failure = "#fd2221",

};

enum FontSize {
    Small,
    Medium,
    Large
};