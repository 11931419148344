import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthConfig } from '@auth/auth.config';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService, LocationAccessPermission } from '@core/services/auth/auth.service';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    protected guardName: string = 'Permission';

    constructor(
        private _auth: AuthService,
        private _router: Router
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        const requiredPermission: LocationAccessPermission = route.data?.requiredPermission || "write";

        return this._auth.activeUserPermissions$.pipe(
            map(() => {
                let hasPermission = this._auth.hasPermission(requiredPermission);
                debug.log(`Guard - ${this.guardName}:`, { [requiredPermission]: hasPermission })
                if (!hasPermission) {
                    return this._router.createUrlTree([AuthConfig.paths.error.forbidden])
                }
                return true;
            }))

    }

}
