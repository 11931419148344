import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxChartsModule } from "@swimlane/ngx-charts";

import { KeysPipe } from './pipes/key-value';

import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { TransactionBenchmarkComponent } from './components/transaction-benchmark/transaction-benchmark.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormUnloadListener } from './lib/FormUnloadListener';
import { AdminWarningComponent } from './components/admin-warning/admin-warning.component';
import { UserRoleDirective } from './directive/user-role/user-role.directive';
import { ClientTypeDirective } from './directive/client-type/client-type.directive';
import { CustomerDeviceComponent } from './components/customer-device/customer-device.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgApexchartsModule
  ],
  declarations: [
    KeysPipe,
    TreeViewComponent,
    FormUnloadListener,
    TransactionBenchmarkComponent,
    AdminWarningComponent,
    UserRoleDirective,
    ClientTypeDirective,
    CustomerDeviceComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    NgxChartsModule,
    NgApexchartsModule,
    KeysPipe,
    FormUnloadListener,
    TreeViewComponent,
    TransactionBenchmarkComponent,
    AdminWarningComponent,
    UserRoleDirective,
    ClientTypeDirective,
    CustomerDeviceComponent
  ]
})
export class SharedModule { }
