import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AsyncStorage } from "@core/services/AsyncStorage";
import { ClientsService, Client } from "@admin/clients/clients.service";
import { APIResponse } from "@shared/types/APIResponse";
import { DebugService as debug } from "@core/services/debug.service";
// import { WhiteboxLog } from "@core/data/whiteboxLog";
import { DashboardRecord } from "@core/data/dashboard";
// import { Whitebox } from "@core/data/controller";
import { map } from "rxjs/operators";
// import { Export } from "@shared/lib/Export";
import { AuthService } from "@core/services/auth/auth.service";

// export { Whitebox };

@Injectable()
export class DashboardService {
  private _activeClientId: string;
  private _dashboardRecords: AsyncStorage<DashboardRecord>;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {
    //Prepare location storage
    this._dashboardRecords = new AsyncStorage(this._http);
    this._dashboardRecords._setElementComparisonFunction(this._dashboardRecordComparison);
    this._dashboardRecords._setUpdateFunction(this._updateDashboardRecord());

    this._clientsService.clients
      .onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._dashboardRecords.update();
      });
  }

  public get dashboardRecords() {
    return this._dashboardRecords;
  }

  private _dashboardRecordComparison(a: DashboardRecord, b: DashboardRecord): boolean {
    //Compare the two
    return a != null && b != null ? a.macAddress === b.macAddress : false;
  }

  private _updateDashboardRecord(): () => Observable<DashboardRecord[]> {
    let self = this;

    return () => {
      debug.log("Updating dashboard records");

      return this._http
        .post("reports/getDashboardControllers", {
          clientId: this._clientsService.clients.activeElement.clientId,
          userId: self._auth.activeUser.userId,
        })
        .pipe(
          map((data: APIResponse) => {
            //Convert the data
            let whiteboxData: { [key: string]: DashboardRecord } = !data.errors.length
              ? data.data
              : {};

            //Convert the dashboard records into an array
            let dashboardRecords: DashboardRecord[] = [];
            for (let i in whiteboxData) {
                dashboardRecords.push(whiteboxData[i]);
            }

            //Sort alphabetically
            dashboardRecords.sort(
              (leftSide: DashboardRecord, rightSide: DashboardRecord): number => {
                if (leftSide.controllerName < rightSide.controllerName) return -1;
                if (leftSide.controllerName > rightSide.controllerName) return 1;
                return 0;
              }
            );

            return dashboardRecords;
          })
        );
    };
  }

//   public async exportCsv(macAddress, startDate, endDate): Promise<void> {
//     const data = await this._http
//       .post(
//         "cwfController/getLogsCsv",
//         {
//           macAddress: macAddress,
//           startTimeStamp: startDate,
//           endTimeStamp: endDate,
//           payload: true,
//         },
//         { responseType: "arraybuffer" }
//       )
//       .toPromise();

//     Export.csv(data, "whitebox-logs.csv");
//   }
}
