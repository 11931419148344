import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';
import { AuthConfig } from '@auth/auth.config';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

    protected guardName: string = 'Logged In';

    constructor(
        private _auth: AuthService,
        private _router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._auth.activeClient$.pipe(
            map(() => {
                let isLoggedIn = this._auth.isLoggedIn;
                debug.log(`Guard - ${this.guardName}: ${isLoggedIn}`)
                if (!isLoggedIn)
                    return this._router.createUrlTree([AuthConfig.paths.login])
                return true
            }))
    }


}
