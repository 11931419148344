import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DebugService as debug } from "@core/services/debug.service";

@Component({
  selector: 'tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent {

  @Input() data;
  @Input() hierarchy;
  private _hierarchy;

  @Output() selectedItem: EventEmitter<any> = new EventEmitter();

  constructor(){
  }

  ngOnChanges(changes) {
    this._hierarchy = Array.of(this.hierarchy);
    debug.log('hierarchy', this.hierarchy);
    debug.log('data', this.data);
}

  selectItem(item) {
    this.selectedItem.emit(item);
  }

  setPadding() {
    return '40px';
  }

}
