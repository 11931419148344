<div class="dialog-wrapper">
    <button class="btn-close" mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <header class="dialog-header" fxLayout="row" fxLayoutAlign="start, center">
        <mat-icon class="dialog-header-ico">local_car_wash</mat-icon>
        <div class="dialog-header-title">
            <h2>Add New Client</h2>
            <p>Please enter the client's details below.</p>
        </div>
    </header>

    <mat-dialog-content>
        <mat-horizontal-stepper #stepper="matHorizontalStepper" linear>

            <!-- Client Type -->
            <mat-step [formGroup]="clientTypeForm" [stepControl]="clientTypeForm" errorMessage="Client type is required.">
                <ng-template matStepLabel>Client Type</ng-template>

                <div class="client-type-container" fxLayout="row" fxLayoutAlign="space-between">

                    <!-- Standard -->
                    <input id="radioStandard" type="radio" value="standard" formControlName="clientType">
                    <label for="radioStandard" class="client-type enabled" fxFlex="32" fxLayoutGap="15px">
                        <div class="client-type-header">
                            <span class="icon"><i class="fad fa-car"></i></span>
                            <h4>Standard</h4>
                        </div>
                        <div>
                            <p>A standard carwash operator experience. Full access to Mosaic's services and features.</p>
                            <h5>Services</h5>
                            <ul>
                                <li class="feature-full">Administration portal</li>
                                <li class="feature-full">Mosaic mobile app</li>
                            </ul>
                            <h5>Features</h5>
                            <ul>
                                <li class="feature-full">Customers</li>
                                <li class="feature-full">Reports</li>
                                <li class="feature-full">Campaigns</li>
                                <li class="feature-full">Locations</li>
                                <li class="feature-full">Products</li>
                                <li class="feature-full">Financials</li>
                                <li class="feature-full">Connections</li>
                                <li class="feature-full">Administrators</li>
                            </ul>
                        </div>
                    </label>

                    <!-- Proxy -->
                    <input id="radioProxy" type="radio" value="proxy" formControlName="clientType">
                    <label for="radioProxy" class="client-type enabled" fxFlex="32" fxLayoutGap="15px">
                        <div class="client-type-header">
                            <span class="icon"><i class="fad fa-car-building"></i></span>
                            <h4>Proxy</h4>
                        </div>
                        <div>
                            <p>A custom carwash operator experience. Limited access to Mosaic's services and features.</p>
                            <h5>Services</h5>
                            <ul>
                                <li class="feature-none">Administration portal</li>
                                <li class="feature-none">Mosaic mobile app</li>
                            </ul>
                            <h5>Features</h5>
                            <ul>
                                <li class="feature-full">Customers</li>
                                <li class="feature-full">Reports</li>
                                <li class="feature-none">Campaigns</li>
                                <li class="feature-full">Locations</li>
                                <li class="feature-full">Products</li>
                                <li class="feature-none">Financials</li>
                                <li class="feature-full">Connections</li>
                                <li class="feature-none">Administrators</li>
                            </ul>
                        </div>
                    </label>

                    <!-- Management -->
                    <input id="radioManagement" type="radio" value="management" formControlName="clientType">
                    <label for="radioManagement" class="client-type enabled" fxFlex="32" fxLayoutGap="15px">
                        <div class="client-type-header">
                            <span class="icon"><i class="fad fa-users"></i></span>
                            <h4>Management</h4>
                        </div>
                        <div>
                            <p>Provides administration portal access to view and configure other carwash clients.</p>
                            <h5>Services</h5>
                            <ul>
                                <li class="feature-full">Administration portal</li>
                                <li class="feature-none">Mosaic mobile app</li>
                            </ul>
                            <h5>Features</h5>
                            <ul>
                                <li class="feature-none">Customers</li>
                                <li class="feature-none">Reports</li>
                                <li class="feature-none">Campaigns</li>
                                <li class="feature-none">Locations</li>
                                <li class="feature-none">Products</li>
                                <li class="feature-none">Financials</li>
                                <li class="feature-none">Connections</li>
                                <li class="feature-full">Administrators</li>
                            </ul>
                        </div>
                    </label>

                </div>
                <div class="stepper-actions" fxLayout="row" fxLayoutAlign="space-between">
                    <button type="button" mat-button color="primary" (click)="closeDialog()">Cancel</button>
                    <button type="button" mat-raised-button matStepperNext color="primary" [disabled]="clientTypeForm.invalid">Continue</button>
                </div>
            </mat-step>

            <!-- Managed Clients -->
            <mat-step *ngIf="'management' == clientTypeForm.get('clientType')?.value" [formGroup]="managedClientsForm" label="Managed Clients" [stepControl]="managedClientsForm" errorMessage="Managed clients are required.">
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <p>
                        Select the clients that will be maintained by the management client:
                    </p>
                    <mat-form-field appearance="fill">
                        <mat-label>Managed Clients</mat-label>
                        <mat-select formControlName="managedClients" class="wide" multiple>
                            <mat-option *ngFor="let client of clients" [value]="client?.clientId">
                                <i class="margin-right far" [class.fa-car]="!client?.type || 'standard' === client?.type" [class.fa-car-building]="'proxy' === client?.type" [class.fa-users]="'management' === client?.type || 'superAdmin' === client?.type"></i>
                                <span *ngIf="client?.clientData?.clientName">{{client?.clientData?.clientName || 'Unknown Client Name' }}</span>
                                <span *ngIf="!client?.clientData?.clientName" class="managed-client-id">{{client?.clientId}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="stepper-actions" fxLayout="row" fxLayoutAlign="space-between">
                    <button type="button" mat-button matStepperPrevious color="primary">Back</button>
                    <button type="button" mat-raised-button matStepperNext color="primary" [disabled]="clientTypeForm.invalid">Continue</button>
                </div>
            </mat-step>

            <!-- Account Details -->
            <mat-step label="Account" [formGroup]="locationForm" [stepControl]="locationForm" errorMessage="Account details are required.">
                <div fxLayout="flex" fxLayoutGap="50px">
                    <div class="details">
                        <h2>Account Details</h2>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="Business Name*" formControlName="clientName">
                                <mat-error>Please enter a name for the business.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Corporate ID*" formControlName="corporateId">
                                <mat-error>Please enter a unique identifier.</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="Address Line 1*" formControlName="address1">
                                <mat-error>Please enter a street address.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Address Line 2" formControlName="address2">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field fxFlex="50">
                                <mat-select placeholder="Country*" formControlName="country">
                                    <mat-option value="Canada">Canada</mat-option>
                                    <mat-option value="USA">United States of America</mat-option>
                                    <mat-option value="Ukraine">Ukraine</mat-option>
                                </mat-select>
                                <mat-error>Please enter a country.</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <mat-select [placeholder]="stateManager['location'].stateOrProvince.singular + '*'" formControlName="state">
                                    <mat-option *ngFor="let state of (stateManager['location'].states)" value="{{ state.abbreviation }}">{{
                                        state.name }}</mat-option>
                                </mat-select>
                                <mat-error>Please enter a {{ stateManager['location'].stateOrProvince.singular.toLowerCase()
                                    }}.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="City*" formControlName="city">
                                <mat-error>Please enter a city.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="ZIP / Postal*" formControlName="code">
                                <mat-error>Please enter a ZIP or postal code.</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" *ngIf="'proxy' != clientTypeForm.get('clientType')?.value">
                            <mat-form-field>
                                <input matInput placeholder="Site URL*" formControlName="url">
                                <span matSuffix>{{siteURLSuffix}}</span>
                                <mat-error>Please enter a url prefix.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="account-rep" *ngIf="'proxy' != clientTypeForm.get('clientType')?.value">
                        <h2>Super-Admin Account
                            <!-- <mat-icon [matTooltipPosition]="'above'" matTooltip="Add some help text.">help_outline</mat-icon> -->
                        </h2>
                        <mat-form-field>
                            <input matInput placeholder="Email*" formControlName="superAdminEmail1" type="email" email>
                            <mat-error>Please enter a valid email address.</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Confirm Email*" formControlName="superAdminEmail2" type="email" email>
                            <mat-error>Emails must match.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="stepper-actions" fxLayout="row" fxLayoutAlign="space-between">
                    <button type="button" mat-button matStepperPrevious color="primary">Back</button>
                    <button type="button" mat-raised-button matStepperNext color="primary" [disabled]="locationForm.invalid">Continue</button>
                </div>
            </mat-step>

            <!-- Contact Details -->
            <mat-step label="Contact" [stepControl]="contactForm" errorMessage="Contact details are required.">
                <div fxLayout="flex" fxLayoutAlign="space-between">
                    <div class="primary" [formGroup]="contactForm.controls['primary']">
                        <h2>Primary Contact</h2>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="First Name*" formControlName="firstName">
                                <mat-error>Please enter a first name.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Last Name*" formControlName="lastName">
                                <mat-error>Please enter a last name.</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="Phone*" formControlName="phoneNumber">
                                <mat-error>Please enter a valid phone number.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Email*" formControlName="email">
                                <mat-error>Please enter a valid email address.</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row">
                            <mat-form-field>
                                <input matInput placeholder="Business Title*" formControlName="title">
                                <mat-error>Please enter a business title.</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="comms">
                            <span>Communication Preference*</span>
                            <mat-radio-group formControlName="commPref">
                                <mat-radio-button value="phone">Phone</mat-radio-button>
                                <mat-radio-button value="email">Email</mat-radio-button>
                                <mat-radio-button value="sms">SMS</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="Address Line 1*" formControlName="address1">
                                <mat-error>Please enter a street address.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Address Line 2" formControlName="address2">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <mat-select placeholder="Country*" formControlName="country">
                                    <mat-option value="Canada">Canada</mat-option>
                                    <mat-option value="USA">United States of America</mat-option>
                                    <mat-option value="Ukraine">Ukraine</mat-option>
                                </mat-select>
                                <mat-error>Please enter a country.</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <mat-select [placeholder]="stateManager['primary'].stateOrProvince.singular + '*'" formControlName="state">
                                    <mat-option *ngFor="let state of (stateManager['primary'].states)" value="{{ state.abbreviation }}">{{
                                        state.name }}</mat-option>
                                </mat-select>
                                <mat-error>Please enter a {{ stateManager['primary'].stateOrProvince.singular.toLowerCase()
                                    }}.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="City*" formControlName="city">
                                <mat-error>Please enter a city.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="ZIP / Postal*" formControlName="code">
                                <mat-error>Please enter a ZIP or postal code.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="billing" [formGroup]="contactForm.controls['billing']">
                        <h2>Billing Contact</h2>
                        <span [formGroup]="contactForm">
                            <mat-checkbox formControlName="billingSameAsPrimary">Copy Primary</mat-checkbox>
                        </span>

                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="First Name*" formControlName="firstName">
                                <mat-error>Please enter a first name.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Last Name*" formControlName="lastName">
                                <mat-error>Please enter a last name.</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="Phone*" formControlName="phoneNumber">
                                <mat-error>Please enter a valid phone number.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Email*" formControlName="email">
                                <mat-error>Please enter a valid email address.</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row">
                            <mat-form-field>
                                <input matInput placeholder="Business Title*" formControlName="title">
                                <mat-error>Please enter a business title.</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="comms">
                            <span>Communication Preference*</span>
                            <mat-radio-group formControlName="commPref">
                                <mat-radio-button value="phone">Phone</mat-radio-button>
                                <mat-radio-button value="email">Email</mat-radio-button>
                                <mat-radio-button value="sms">SMS</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="Address Line 1*" formControlName="address1">
                                <mat-error>Please enter a street address.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Address Line 2" formControlName="address2">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <mat-select placeholder="Country*" formControlName="country">
                                    <mat-option value="Canada">Canada</mat-option>
                                    <mat-option value="USA">United States of America</mat-option>
                                    <mat-option value="Ukraine">Ukraine</mat-option>
                                </mat-select>
                                <mat-error>Please enter a country.</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <mat-select [placeholder]="stateManager['billing'].stateOrProvince.singular+'*'" formControlName="state">
                                    <mat-option *ngFor="let state of (stateManager['billing'].states)" value="{{ state.abbreviation }}">{{
                                        state.name }}</mat-option>
                                </mat-select>
                                <mat-error>Please enter a {{ stateManager['billing'].stateOrProvince.singular.toLowerCase()
                                    }}.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px">
                            <mat-form-field>
                                <input matInput placeholder="City*" formControlName="city">
                                <mat-error>Please enter a city.</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="ZIP / Postal*" formControlName="code">
                                <mat-error>Please enter a ZIP or postal code.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- <div class="stepper-actions" fxLayout="row" fxLayoutAlign="space-between">
                    <button type="button" mat-button matStepperPrevious color="primary">Back</button>
                    <button type="button" mat-raised-button matStepperNext color="primary" [disabled]="contactForm.invalid">Continue</button>
                </div> -->
                <div class="stepper-actions" fxLayout="row" fxLayoutAlign="space-between">
                    <button type="button" mat-button matStepperPrevious color="primary">Back</button>
                    <button type="button" mat-raised-button matStepperNext color="primary" (click)="save()" [disabled]="contactForm.invalid">Create Client</button>
                </div>
            </mat-step>

            <!-- Modules -->
            <!-- <mat-step label="Modules">
                <div fxLayout="flex" fxLayoutAlign="space-between">
                    <div class="mandatory">
                        <h2>Mandatory Modules</h2>
                        <mat-list-item class="module" *ngFor="let controlName of getControlNames(moduleForm.controls['mandatory'])" [formGroup]="getControl(moduleForm.controls['mandatory'],controlName)">
                            <mat-checkbox formControlName="enabled">{{ features[controlName]?.name }}</mat-checkbox>
                            <mat-form-field>
                                <input matInput placeholder="Flat" type="number" formControlName="flat">
                                <span matPrefix>$&nbsp;</span>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Monthly" type="number" formControlName="monthly">
                                <span matPrefix>$&nbsp;</span>
                                <span matSuffix>/mo.</span>
                            </mat-form-field>
                        </mat-list-item>
                    </div>
                    <div class="optional">
                        <h2>Optional Modules</h2>
                        <mat-list-item class="module" *ngFor="let controlName of getControlNames(moduleForm.controls['optional'])" [formGroup]="getControl(moduleForm.controls['optional'],controlName)">
                            <mat-checkbox formControlName="enabled">{{ features[controlName]?.name }}</mat-checkbox>
                            <mat-form-field>
                                <input matInput placeholder="Flat" type="number" formControlName="flat">
                                <span matPrefix>$&nbsp;</span>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Monthly" type="number" formControlName="monthly">
                                <span matPrefix>$&nbsp;</span>
                                <span matSuffix>/mo.</span>
                            </mat-form-field>
                        </mat-list-item>
                    </div>
                </div>
                <div class="stepper-actions" fxLayout="row" fxLayoutAlign="space-between">
                    <button type="button" mat-button matStepperPrevious color="primary">Back</button>
                    <button type="button" mat-raised-button matStepperNext color="primary" (click)="save()" [disabled]="moduleForm.invalid">Create Client</button>
                </div>
            </mat-step> -->

        </mat-horizontal-stepper>

    </mat-dialog-content>
</div>