import { Injectable } from '@angular/core';
import { Objects } from '@carwashconnect/cwc-core-js';

const LocalStorageKey: string = "accessHistory";

@Injectable({ providedIn: 'root' })
export class LocalHistoryService {

    constructor() { }

    public get history(): Record<string, any> {
        return JSON.parse(localStorage.getItem(LocalStorageKey)) || {};
    }

    public set history(value: Record<string, any>) {
        let currentHistory = this.history;
        if (!Objects.isObject(value)) return;
        let newHistory = Objects.merge(currentHistory, value);
        localStorage.setItem(LocalStorageKey, JSON.stringify(newHistory));
    }

}
