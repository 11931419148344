export class TransactionConfigData {
    clientId: string;
    data: TransactionConfigLocationData
    timeStamp?: number;
    sandboxData?: string;

    constructor() {
        this.clientId = "";
        this.data = new TransactionConfigLocationData();
    }
}

export class TransactionConfigLocationData {
    ids: string[];
    locations: { [key: string]: TransactionConfig }

    constructor() {
        this.ids = [];
        this.locations = {};
    }
}

export class TransactionConfig {
    locationId: string;
    currency: "CAD" | "USD";
    taxes: { [key: string]: string };
    creditCard: {
        storeId?: string,
        processor: "" | "stripe" | "moneris",
        id: string,
        isSharingEnabled: boolean;
    };
    missingPayments?: {

        /** The delay in milliseconds before the next retry. */
        delay?: number;

        /** On which attempt should an email be sent to notify the customer. */
        emailFrequency?: string;

        /** The number of attempts made by the payment processor to recover from the error. */
        retries?: number;

    }

    paymentFailure?: {

        /** The delay in milliseconds before the next retry. */
        delay?: number;

        /** On which attempt should an email be sent to notify the customer. */
        emailFrequency?: string;

        /** The number of attempts made by the payment processor to recover from the error. */
        retries?: number;

    }

    fees: {
        digital: Fee,
        cwc: Fee
    }

    constructor(locationId: string) {
        this.locationId = locationId;
        this.currency = "USD";
        this.creditCard = {
            processor: "",
            id: "",
            isSharingEnabled: false
        };
        this.fees = {
            digital: new Fee(),
            cwc: new Fee()
        }
        this.taxes = {};
    }
}

export class Fee {
    target: "client" | "customer";
    value: number;
    includeInPrice: boolean;
    directSale: number;
    subscriptionSale: number;

    constructor() {
        this.target = "client";
        this.value = 0.30;
        this.includeInPrice = false;
    }
}

export interface Transaction {
    clientId: string;
    codeModule: string;
    locationId: string;
    paymentModule: string;
    saleType: string;
    status: string;
    transactionId: string;
    userId: string;

    //TimeStamps
    createdOn: number;
    modifiedOn: number;
    cancelledOn?: number;
    expiredOn?: number;
    usedOn?: number;
    completedOn?: number;
    fulfillOn?: number;
    refundedOn?: number;

    //Optional
    refundId?: string;
    completed?: boolean;
    upgrade?: boolean;
    downgrade?: boolean;

    benchmark?: TransactionBenchMarkData

    transactionData: {
        codeModuleInput?: { [key: string]: any };
        codeModuleOutput?: { [key: string]: any };
        paymentModuleInput?: {
            [key: string]: any
            amountObject: {
                chargeDescription: string,
                clientCwfFee: number,
                clientStripeFee: number,
                currency: string,
                customerCwfFee: number,
                customerStripeFee: number,
                price: number,
                tax: number
            }
        };
        paymentModuleOutput?: { [key: string]: any };
        transactionOutput?: { [key: string]: any };
    }
    saleData?: TransactionSaleData
    userData: {
        [key: string]: any
        email: string;
    }
}

export interface TransactionBenchMarkData {
    databaseRead: TransactionBenchMark;
    payment: TransactionBenchMark
    codeRetrieval: TransactionBenchMark
    transaction: TransactionBenchMark
    precision: {
        measure: number;
        unit: string;
    }
}

export interface TransactionBenchMark {
    tableCount?; number;
    moduleName: string;
    time: number;
    status: string;
}

export interface TransactionSaleData {
    saleId: string;
    name: string;
    type: "subscription" | "direct";
    productData: {
        id: string,
        clientId: string
    };
    paymentRecurrence?: string;
    productReleaseFrequency?: {
        unlimited: boolean
        day: {
            unlimited: boolean,
            qty: string
        },
        week: {
            unlimited: boolean,
            qty: string
        },
        month: {
            unlimited: boolean,
            qty: string
        }

    }
    pricing: {
        consistent: number,
        price: number
    };
    loyalty: {
        allowLoyalty: boolean,
        consistent: number,
        programId: string,
        cost: number,
        earned: number
    };
    allLocations: boolean;
    location: {
        currency: string;
        locationId: string;
        loyalty: {
            cost: number;
            earned: number;
            programId: number;
        }
        price: number;
        priceWithTaxes: number;
        taxes: {
            amount: number;
            country: string;
            idOnReceipt: boolean;
            includeNextLevel: boolean;
            name: string;
            rate: number;
            state: string;
            taxId: string;
            type: string
        }[];
        taxIds: {
            federal: string[],
            municipal: string[],
            state: string[]
        }
    }

    refunds: {
        refund: boolean
    };
    taxes: {
        included: boolean
    };
    sharing: {
        allow: boolean,
        groupId: string
    };
    geolocation: {
        forceOnsite: boolean,
        geoMethodId: string
    };
    branding: {
        ctaButton: string
    }
}
