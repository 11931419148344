import { Image } from "./image";
// import { DebugService as debug } from "@core/services/debug.service";

export { Image } from "./image";

export class ProductCategoryData {
    clientId: string;
    productData: {
        products: Array<string>,
        [product: string]: Product | Array<string>
    };
    categoryData: {
        categories: Array<string>,
        [category: string]: Category | Array<string>
    }
    timeStamp: number;

    constructor() {
        this.clientId = "";
        this.productData = {
            products: []
        }

        this.categoryData = {
            categories: []
        }
        this.timeStamp = 0;
    }
}

export class Product {
    productId: string;
    category: string;
    name: string;
    description: string;
    teasers: string[];
    additional: string;
    images: Image[];

    constructor() {
        this.productId = ""; //THIS NEEDS TO BE UNIQUE
        this.category = "";
        this.name = "";
        this.description = "";
        this.teasers = [];
        this.additional = "";
        this.images = [];
    }

}

// Object.defineProperty(Product, Symbol.hasInstance, {
//     value: (i) => {
//         debug.log(JSON.stringify(i.images));
//         if ("object" != typeof i) return false;
//         if ("string" == typeof i.productId &&
//             "string" == typeof i.category &&
//             "string" == typeof i.name &&
//             "string" == typeof i.description &&
//             Array.isArray(i.images))
//             return true;
//         else
//             return false
//     }
// })

export class Category {
    categoryId: string;
    name: string;
    description: string;

    constructor() {
        this.categoryId = "";
        this.name = "";
        this.description = "";
    }
}

