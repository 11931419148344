
import { map } from 'rxjs/operators';
// https://ryanchenkie.com/angular-authentication-using-the-http-client-and-http-interceptors

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { DebugService as debug } from "@core/services/debug.service";
import { JWTTokens, LocalStorageKeys } from './auth/auth.service';

const API_KEY: string = environment.API_KEY;
const API_URL: string = environment.APP_URL;
const IS_PROD: boolean = environment.production;


@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

    constructor() { }


    /**
     * HTTP Interceptor
     * 
     * @param {HttpRequest<any>} req 
     * @param {HttpHandler} next 
     * @returns {Observable<HttpEvent<any>>} 
     * @memberof HttpResponseInterceptor
     */

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // if this is an non-CWC api call send it as is
        if (req.url.indexOf('http') == 0) {
            return next.handle(req);
        } else {


            // Modify the request with the necessary headers and api url for the CWC api
            let updatedReq = this._prepareRequest(req);

            return next.handle(updatedReq).pipe(map((res: HttpEvent<any>) => {
                return this._log(res);
            }))

        }



    }

    private _log(res: HttpEvent<any>): HttpEvent<any> {

        // logger
        if (!IS_PROD && res.type != 0) {
            // debug.log('HTTP Request', updatedReq)
            // debug.log('HTTP Response', res);
        }

        return res;
    }

    // PREPARE HTTP HEADERS and API URL
    private _prepareRequest(req: HttpRequest<any>): HttpRequest<any> {

        //Load the tokens from local storage
        let tokens: JWTTokens = JSON.parse(localStorage.getItem(LocalStorageKeys.Tokens))

        //Prepare the data
        let authData = {
            key: API_KEY,
            url: window.location.href.includes("localhost:4200") ? undefined : window.location.href,
            token: tokens?.idToken
        };

        //Encode to base64 for transmission
        let authHeader: string = btoa(JSON.stringify(authData));

        let newReq = req.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': authHeader
            },
            url: API_URL + req.url,
            body: JSON.stringify(req.body)
        });

        return newReq;
    }

}