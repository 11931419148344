
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest } from "rxjs";
import { AsyncStorage } from "@core/services/AsyncStorage";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService } from '@admin/clients/clients.service';
import { UserService } from '@admin/users/user.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class LocationAccessService {

    private _locationAccess: AsyncStorage<any>;

    private _targetClientId: string;
    private _activeClientId: string;
    private _activeCustomerId: string;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _userService: UserService,
        private _auth: AuthService) {
        this._initializeStorage();
    }

    public get activeClientId(): string { return this._activeClientId };

    private _initializeStorage() {

        //Prepare transaction storage
        this._locationAccess = new AsyncStorage(this._http);
        this._locationAccess._setUpdateFunction(this._updateLocationAccess());
        this._locationAccess._setAddFunction(this._save());

        this._locationAccess._setSaveActiveElementFunction(this._save());

        combineLatest([
            this._clientsService.clients.onActiveElementChange(),
            this._userService.user.onUpdate()
        ]).subscribe((data: any[]) => {
            this._targetClientId = data[0].clientId;
            this._activeCustomerId = data[1][0].userId;

            //Don't update if we don't have our data
            if (!this._targetClientId || !this._activeCustomerId) return

            //Don't bother if the client ids don't match
            if (data[1][0].clientId != data[0].clientId) return;

            this._locationAccess.update();

        });
    }

    public get locationAccess() {
        return this._locationAccess
    };

    private _save(): (data) => Observable<any> {
        let self = this;

        return (data) => {
            debug.log("saving location access");
            return new Observable((observer) => {

                let requestInput = {
                    clientId: self._activeClientId || self._auth.activeClient?.clientId,
                    userId: self._activeCustomerId,
                    data: data
                };

                let add = () => {
                    return this._http.post("locationAccess/add", requestInput).toPromise()
                        .then((data: APIResponse) => { observer.next(data); })
                        .catch(error => { observer.next(); })
                }

                this._http.post("locationAccess/update", requestInput).toPromise()
                    .then((res: any) => {
                        if (0 == Object.keys(data.data).length || Object.keys(data.errors).length) {
                            return add()
                        } else {
                            return observer.next(data)
                        }
                    })
                    .catch(() => { return add() })


            });
        }
    }

    private _updateLocationAccess(): () => Observable<any> {
        let self = this;

        return () => {
            debug.log("getting location access");
            return this._http.post("locationAccess/get", {
                clientId: self._targetClientId || self._auth.activeClient?.clientId,
                userId: self._activeCustomerId
            }).pipe(
                map((data: APIResponse) => {

                    this._activeClientId = this._targetClientId;

                    //Convert the data
                    return (data.data || {}).data || {};
                }));
        }
    }
}

