import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { User } from "@core/data/user";
import { UserReport, UserReportData } from "@core/data/userReport";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { LoyaltyProgram } from '@financial/loyalty/loyalty.service';
import { UserService } from '@admin/users/user.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

export { AsyncStorage } from "@core/services/AsyncStorage";
export { UserReport, UserReportData } from "@core/data/userReport";
export { User } from "@core/data/user";

const USER_TYPES = {
    CUSTOMER: "customer",
    CLIENT: "client",
    MOSAIC: "cwc"
}

export { USER_TYPES };

@Injectable()
export class UserReportService {

    private _allUsers: AsyncStorage<UserReportData>;
    private _targetClientId: string;
    private _activeAllUsersClientId: string;

    // user pagination
    private _page: number = 1;
    private _pageLength: number = 10;

    // user filters
    private _status: string[] = [];
    private _hasSubscriptions: boolean = false;
    private _filter: string = "";
    private _sortBy = "name";
    private _sortDirection = "asc";
    private _userRoles = [USER_TYPES.CUSTOMER, USER_TYPES.CLIENT];

    private _pseudoUsers: { [clientId: string]: UserReport[] } = {};

    constructor(
        private _http: HttpClient,
        private _auth: AuthService,
        private _clientsService: ClientsService,
        private _usersService: UserService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare location storage
        this._allUsers = new AsyncStorage(this._http);
        //this._allUsers._setElementComparisonFunction(this._userComparison);
        this._allUsers._setUpdateFunction(this._updateUsers());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._allUsers.update();
            });
    }

    public get users() { return this._allUsers };
    public get usersClientId(): string { return this._activeAllUsersClientId };

    public addPseudoUser(user: UserReport): void {

        //Ensure there is an array for this client
        this._pseudoUsers[user.clientId] = this._pseudoUsers[user.clientId] || [];
        let foundUser: boolean = false;

        //Update user if the user is found
        for (let i in this._pseudoUsers[user.clientId]) {
            if (!this._userComparison(this._pseudoUsers[user.clientId][i], user)) continue;
            foundUser = true;
            this._pseudoUsers[user.clientId][i] = user;
            break;
        }

        //Add user if not found
        if (!foundUser)
            this._pseudoUsers[user.clientId].push(user);

    }

    private _removePseudoUsers(users: UserReport[]): void {
        if (!users.length) return

        //Loop through provided users
        for (let user of users) {

            //If there's no list there's no pseudo users to remove
            if (!this._pseudoUsers[user.clientId] || !this._pseudoUsers[user.clientId].length) continue;

            //Remove the user if found
            for (let i = 0; i < this._pseudoUsers[user.clientId].length; i++) {
                if (!this._userComparison(this._pseudoUsers[user.clientId][i], user)) continue;
                this._pseudoUsers[user.clientId].splice(i, 1);
                break;
            }

        }

    }

    public async addUsers(emails: String[]): Promise<any> {

        //Add the users with the user service
        let addUsersResponse: APIResponse;
        try {

            addUsersResponse = await this._usersService.addUsers(emails);
            debug.log(addUsersResponse);

            if (addUsersResponse.data) {
                for (let i in addUsersResponse.data.users || {}) {
                    let user: User = addUsersResponse.data.users[i]
                    let reportUser: UserReport = {
                        clientId: user.clientId,
                        freeWashCount: 0,
                        email: user.email,
                        userId: user.userId,
                        type: user.type,
                        ssPurchaseCount: 0,
                        ssUsedCount: 0,
                        refundCount: 0,
                        status: "enable",
                        createdOn: (new Date()).getTime(),
                        isNewUser: user.newUser
                    };
                    this.addPseudoUser(reportUser)
                }
            }

        } catch (e) {
            debug.error(e);
            return Promise.reject(e)
        }

        //Update the users to trigger the notification
        this.users.update();

        return Promise.resolve(addUsersResponse.data);

    }

    private _userComparison(a: UserReport, b: UserReport): boolean {
        if (!a || !b) return false
        //Compare the two
        return a.userId == b.userId ? true : false;

    }

    public paginateUsers(page: number, pageLength: number, status: string[], hasSubscriptions: boolean, filter: string, sortBy: string, sortDirection: string, userRoles: string[]) {
        this._page = page || this._page;
        this._pageLength = pageLength || this._pageLength;
        this._status = (Array.isArray(this._status) ? status : this._status);
        this._hasSubscriptions = hasSubscriptions;
        this._filter = filter;
        this._sortBy = sortBy;
        this._sortDirection = sortDirection;
        this._userRoles = userRoles;


        if (!this._targetClientId) return;

        this._allUsers.update();
    }

    private _updateUsers(): (options?: any) => Observable<UserReportData[]> {
        let self = this;
        return (lastEvaluatedKey?: any) => {

            //Prepare the inputs
            let inputs: { [key: string]: any } = {
                clientId: self._targetClientId,
                page: this._page,
                batchQuantity: this._pageLength,
                status: this._status,
                hasSubscriptions: this._hasSubscriptions,
                filter: this._filter,
                sortBy: this._sortBy,
                sortDirection: this._sortDirection,
                roles: this._userRoles
            }

            //Add the last key if present
            if ("undefined" != typeof lastEvaluatedKey) {
                inputs.lastEvaluatedKey = lastEvaluatedKey;
            }

            //return this.getUsers(inputs);
            return new Observable((observer) => {
                let promises: Promise<UserReportData[]>[] = [];

                promises.push(this.getUsers(inputs));

                Promise.all(promises).then((data: UserReportData[][]) => {
                    observer.next(data[0]);
                });
            });
        }

    }

    public async getUserCsvExport(): Promise<UserReportData[]> {
        //Prepare the inputs
        let inputs: { [key: string]: any } = {
            clientId: this._targetClientId,
            status: this._status,
            hasSubscriptions: this._hasSubscriptions,
            filter: this._filter,
            sortBy: this._sortBy,
            sortDirection: this._sortDirection,
            roles: this._userRoles
        }
        return await this.getUsers(inputs);
    }

    private getUsers(inputData: { [key: string]: any }): Promise<UserReportData[]> {
        let getUserApiCall = (inputs): Promise<UserReportData[]> => {
            return new Promise(async (resolve, reject) => {


                var subscription = this._http.post("reports/getCustomerReport", inputs)
                    .subscribe(async (data: APIResponse) => {

                        this._activeAllUsersClientId = this._targetClientId;
                        let userReportData: UserReportData = data.data;

                        // clear out any records that are greater than one minute
                        if (this._pseudoUsers[this._activeAllUsersClientId] && Array.isArray(this._pseudoUsers[this._activeAllUsersClientId])) {
                            let expiryTimestamp = (new Date).getTime() - 60000;

                            for (let count = this._pseudoUsers[this._activeAllUsersClientId].length - 1; count >= 0; count--) {
                                let user = this._pseudoUsers[this._activeAllUsersClientId][count];

                                if (user.createdOn < expiryTimestamp)
                                    this._pseudoUsers[this._activeAllUsersClientId].splice(count, 1);
                            }

                        }

                        if (userReportData.LastEvaluatedKey && !inputs.batchQuantity) {
                            inputs.lastEvaluatedKey = userReportData.LastEvaluatedKey;
                            let nextDataSet = await getUserApiCall(inputs);
                            userReportData.Items = [].concat(userReportData.Items, nextDataSet[0].Items);
                        }

                        userReportData.CachedUsers = this._pseudoUsers[this._activeAllUsersClientId];
                        return resolve([userReportData]);

                    }, reject);
            });

        }

        return getUserApiCall(inputData);
    }


    public async getDynamoUser(userId: string): Promise<User> {
        let user = null;

        try {
            const data = await this._http.post("users/getUsers", { clientId: this._targetClientId || this._auth.activeClient?.clientId, userId: userId }).toPromise();
            if (data["data"] && Array.isArray(data["data"]) && data["data"].length > 0) {
                user = data["data"][0];
            }
        } catch (ex) { }

        return user;
    }
}

export interface SaveUserInput {
    loyaltyPrograms: LoyaltyProgram[];
    addLoyalty: number;
    notes: string;
}

interface RootFilterOptions {
    onlyAdmin?: boolean;
    onlyCustomer?: boolean;
}
