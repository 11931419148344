import {HostListener,Directive,Input} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormState } from './Forms';

@Directive({selector: '[formUnloadListener]'})
export class FormUnloadListener {
    @Input() formGroup: FormGroup;

    ngOnInit(): any {
        const formState = FormState.getInstance();
        formState.addForm(this.formGroup)
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event:any) {
      return this.formGroup ? !this.formGroup.dirty : false;
      //I have used return false but you can your other functions or any query or condition
    }
}