import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AsyncStorage } from "@core/services/AsyncStorage";
import { ClientsService, Client } from "@admin/clients/clients.service";
import { APIResponse } from "@shared/types/APIResponse";
import { DebugService as debug } from "@core/services/debug.service";
import { WhiteboxLog } from "@core/data/whiteboxLog";
import { Whitebox } from "@core/data/controller";
import { map } from "rxjs/operators";
import { Export } from "@shared/lib/Export";
import { AuthService } from "@core/services/auth/auth.service";

export { Whitebox };

@Injectable()
export class WhiteboxLogService {
  private _activeClientId: string;
  private _whiteboxes: AsyncStorage<Whitebox>;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {
    //Prepare location storage
    this._whiteboxes = new AsyncStorage(this._http);
    this._whiteboxes._setElementComparisonFunction(this._whiteboxComparison);
    this._whiteboxes._setUpdateFunction(this._updateWhitebox());

    this._clientsService.clients
      .onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._whiteboxes.update();
      });
  }

  public get whiteboxes() {
    return this._whiteboxes;
  }

  private _whiteboxComparison(a: Whitebox, b: Whitebox): boolean {
    //Compare the two
    return a != null && b != null ? a.macAddress === b.macAddress : false;
  }

  private _updateWhitebox(): () => Observable<Whitebox[]> {
    let self = this;

    return () => {
      debug.log("Updating whiteboxes accounts");

      return this._http
        .post("cwfController/get", {
          clientId: self._activeClientId || self._auth.activeClient?.clientId,
        })
        .pipe(
          map((data: APIResponse) => {
            //Convert the data
            let whiteboxData: { [key: string]: Whitebox } = !data.errors.length
              ? data.data.data
              : {};

            //Convert the whiteboxes into an array
            let whiteboxes: Whitebox[] = [];
            for (let i in whiteboxData) {
              whiteboxes.push(whiteboxData[i]);
            }

            //Sort alphabetically
            whiteboxes.sort(
              (leftSide: Whitebox, rightSide: Whitebox): number => {
                if (leftSide.nickname < rightSide.nickname) return -1;
                if (leftSide.nickname > rightSide.nickname) return 1;
                return 0;
              }
            );

            return whiteboxes;
          })
        );
    };
  }

  public async getLogs(macAddress, startDate, endDate): Promise<WhiteboxLog[]> {
    let data = null;

    try {
      data = await this._http
        .post("cwfController/getLogs", {
          macAddress: macAddress,
          startTimeStamp: startDate,
          endTimeStamp: endDate,
          payload: true,
        })
        .toPromise();
    } finally {
      // empty
    }

    return data && data.data
      ? Array.isArray(data.data)
        ? data.data
        : [data.data]
      : [];
  }

  public async exportCsv(macAddress, startDate, endDate): Promise<void> {
    const data = await this._http
      .post(
        "cwfController/getLogsCsv",
        {
          macAddress: macAddress,
          startTimeStamp: startDate,
          endTimeStamp: endDate,
          payload: true,
        },
        { responseType: "arraybuffer" }
      )
      .toPromise();

    Export.csv(data, "whitebox-logs.csv");
  }
}
