import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        ConfirmationDialogComponent,
        DeleteDialogComponent
    ],
    providers: []
})
export class AlertsModule { }
