import { environment } from "../../../environments/environment";

export class DebugService {

  static isMosaic: boolean = false;
  static isLoggedIn: boolean = false;

  static get error(): (message?: any, ...optionalParams: any[]) => void {
    if (environment.DEBUG || DebugService.isMosaic)
      return console.error;
    return () => { }
  }

  static get info(): (message?: any, ...optionalParams: any[]) => void {
    if (environment.DEBUG || DebugService.isMosaic)
      return console.info;
    return () => { }
  }

  static get log(): (message?: any, ...optionalParams: any[]) => void {
    if (environment.DEBUG || DebugService.isMosaic)
      return console.log;
    return () => { }
  }

  static get system(): (message?: any, ...optionalParams: any[]) => void {
    return console.info;
  }

  static get table(): (...tabularData: any[]) => void {
    if (environment.DEBUG || DebugService.isMosaic)
      return console.table;
    return () => { }
  }

  static get trace(): (message?: any, ...optionalParams: any[]) => void {
    if (environment.DEBUG || DebugService.isMosaic)
      return console.trace;
    return () => { }
  }

  static get warn(): (message?: any, ...optionalParams: any[]) => void {
    if (environment.DEBUG || DebugService.isMosaic)
      return console.warn;
    return () => { }
  }

}