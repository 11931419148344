export class KesseltronicsData {
    kesseltronicsIds: string[] = [];
    kesseltronicsAccount: { [key: string]: KesseltronicsAccount } = {};
    lastId: number = 0;
    applications: { [locationId: string]: KesseltronicsApplication } = {};
}

export interface KesseltronicsApplication {
    accountId: string;
    locationId: string;
    siteId: string;
    expiry: number;
}

export class KesseltronicsAccount {
    kesseltronicsId: string;
    userEmail: string;
    password: string;
    apiUrl: string;
    codeLength: number;
    nickname: string;
    expiry: number;
    apiMap: {
        [key: string]: KesseltronicsAPIMap
    }
    availableLocations?: string[];

    constructor(account: KesseltronicsAccount) {
        this.kesseltronicsId = account.kesseltronicsId || "kesseltronicsAccount";
        this.userEmail = account.userEmail || "";
        this.password = account.password || "";
        this.apiUrl = account.apiUrl || "";
        this.codeLength = account.codeLength || 6;
        this.nickname = account.nickname || "";
        this.expiry = account.expiry || 30;
        this.apiMap = account.apiMap || {};
        this.availableLocations = Array.isArray(account.availableLocations) ? account.availableLocations : Object.values(account.availableLocations ?? {})
    }
}

export interface KesseltronicsAPIMap {
    id: number;
    value: number;
    name: string;
    saleId?: string;
}
