
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Role, RoleData } from "@core/data/roles";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

export { AsyncStorage } from "@core/services/AsyncStorage";
export { Role, RoleData } from "@core/data/roles";

@Injectable()
export class RoleService {

  private _roles: AsyncStorage<Role>;
  private _hierarchy: BehaviorSubject<Role[]> = new BehaviorSubject(null);
  private _activeClientId: string;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._roles = new AsyncStorage(this._http);
    this._roles._setAddFunction(this._saveRoles());
    this._roles._setElementComparisonFunction(this._roleComparison);
    this._roles._setSaveActiveElementFunction(this._saveRoles());
    this._roles._setUpdateFunction(this._updateRoles());

    //Change data based on currently selected client
    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._activeClientId = client.clientId;
        this._roles.update();
      });

  }

  public get roles() { return this._roles };
  public get hierarchy() { return this._hierarchy };

  private _saveRoles(): (location: Location) => Observable<any> {
    let self = this;

    return (garbage: any, options?: any): Observable<any> => {

      //Create the role data
      let roleData = new RoleData(self._roles.data.value);

      //Create the request object
      let requestData = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId,
        data: roleData
      }

      //Update or add the item
      return new Observable(observer => {
        this._http.post("users/updateRole", requestData).toPromise()
          .then((data: APIResponse) => {
            if (0 == Object.keys(data.data).length || data.errors.length)
              //Add if we get an error
              this._http.post("users/addRole", requestData).toPromise()
                .then((data: APIResponse) => { observer.next(data); })
                .catch(error => { observer.next(); })
            else
              observer.next(data);
          })
          .catch(error => {
            //Add if we get an error
            this._http.post("users/addRole", requestData).toPromise()
              .then((data: APIResponse) => { observer.next(data); })
              .catch(error => { observer.next(); })

          })
      })
    }

  }

  private _roleComparison(a: Role, b: Role): boolean {

    //Compare the two
    return a.id === b.id;

  }

  private _updateRoles(): () => Observable<Role[]> {

    let self = this;
    return () => {

      debug.log("Updating roles");

      let requestData = {
        clientId: self._activeClientId || self._auth.activeClient?.clientId
      }

      return this._http.post("user/getRoles", requestData).pipe(
        map((data: APIResponse) => {

          //Grab the data
          let rolesData: RoleData = data.data;

          //Copy over the roles
          let roles: Role[] = [];
          for (let i in rolesData.roles) {
            roles.push(rolesData[i])
          }

          //Sort by name
          roles.sort((leftSide, rightSide): number => {
            if (leftSide.name < rightSide.name) return 1
            if (leftSide.name > rightSide.name) return -1
            return 0;
          });

          //Copy the hierarchy
          self._hierarchy.next(rolesData.hierarchy);

          // return all locations
          return roles;

        }));
    }

  }





}