
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { LoyaltyData, LoyaltyProgram, LOYALTY_PROGRAM_TYPES } from "@core/data/loyalty";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { Random } from '@shared/lib/Random';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';

export { LoyaltyProgram, LOYALTY_PROGRAM_TYPES };

@Injectable()
export class LoyaltyService {

    private _loyaltyPrograms: AsyncStorage<LoyaltyProgram>;
    private _activeClientId: string;
    private _targetClientId: string;
    private _lastUsedId: number = 0;


    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        debug.log("Loyalty service id:", Random.string(8))
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare location storage
        this._loyaltyPrograms = new AsyncStorage(this._http);
        this._loyaltyPrograms._setAddFunction(this._saveLoyaltyPrograms());
        this._loyaltyPrograms._setElementComparisonFunction(this._loyaltyProgramComparison);
        this._loyaltyPrograms._setSaveActiveElementFunction(this._saveLoyaltyPrograms());
        this._loyaltyPrograms._setUpdateFunction(this._updateLoyaltyPrograms());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._loyaltyPrograms.update();
            });

    }

    public get loyaltyPrograms() { return this._loyaltyPrograms };
    public get activeClientId() { return this._activeClientId };
    public get nextId(): string { return this._lastUsedId++ , "loyaltyProgram" + this._lastUsedId; };

    private _loyaltyProgramComparison(a: LoyaltyProgram, b: LoyaltyProgram): boolean {

        //Compare the two
        return a != null && b != null ? a.loyaltyId === b.loyaltyId : false;

    }

    private _saveLoyaltyPrograms(): (garbage: any, options?: any) => Observable<any> {

        let self = this;

        return (garbage: any, options?: any): Observable<any> => {

            //Add loyaltyPrograms
            let loyaltyPrograms: LoyaltyProgram[] = self._loyaltyPrograms.data.value;
            let loyaltyProgramData: LoyaltyData = new LoyaltyData();

            //Prepare the object for storing
            for (let loyaltyProgram of loyaltyPrograms) {

                //Add the id to the array
                loyaltyProgramData.programIds.push(loyaltyProgram.loyaltyId)

                //Add the loyaltyProgram
                loyaltyProgramData.programs[loyaltyProgram.loyaltyId] = loyaltyProgram;

                //If there is a new default program, turn others off
                if (loyaltyProgram.isDefault && self._loyaltyPrograms.activeElement.isDefault && loyaltyProgram.loyaltyId != self._loyaltyPrograms.activeElement.loyaltyId) {
                    loyaltyProgram.isDefault = false
                } else if (loyaltyProgram.isDefault) {
                    //Save the default program in the root level of the loyalty program data
                    loyaltyProgramData.defaultProgram = loyaltyProgram.loyaltyId
                }

            }

            //Set the final used index
            loyaltyProgramData.lastId = this._lastUsedId;

            //Prepare the data to send
            let requestData = {
                clientId: self._targetClientId || self._auth.activeClient?.clientId,
                data: loyaltyProgramData
            };

            //Update or add the item
            return new Observable(observer => {
                this._http.post("loyalty/update", requestData).toPromise()
                    .then((data: APIResponse) => {
                        if (0 == Object.keys(data.data).length || data.errors.length)
                            //Add if we get an error
                            this._http.post("loyalty/add", requestData).toPromise()
                                .then((data: APIResponse) => { observer.next(data); })
                                .catch(error => { observer.next(); })
                        else
                            observer.next(data);
                    })
                    .catch(error => {
                        //Add if we get an error
                        this._http.post("loyalty/add", requestData).toPromise()
                            .then((data: APIResponse) => { observer.next(data); })
                            .catch(error => { observer.next(); })

                    })
            })
        }

    }

    private _updateLoyaltyPrograms(): () => Observable<LoyaltyProgram[]> {
        let self = this;

        return () => {
            debug.log("Updating loyalty programs");

            return this._http.post("loyalty/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
                map((data: APIResponse) => {

                    this._activeClientId = this._targetClientId;
                    
                    //Convert the data
                    let loyaltyProgramData: LoyaltyData = data.data ? data.data.data : null;

                    //Create a new object if nothing was found
                    if (!loyaltyProgramData) loyaltyProgramData = new LoyaltyData();

                    //If there is no default program, set it to the first one in the list of programs
                    if (loyaltyProgramData && !loyaltyProgramData.defaultProgram && loyaltyProgramData.programIds.length > 0) {
                        loyaltyProgramData.defaultProgram = loyaltyProgramData.programIds[0]
                        loyaltyProgramData.programs[loyaltyProgramData.programIds[0]].isDefault = true
                    }

                    //Convert the loyaltyPrograms into an array
                    let loyaltyPrograms: LoyaltyProgram[] = [];
                    for (let i in loyaltyProgramData.programs) {
                        loyaltyPrograms.push(loyaltyProgramData.programs[i]);
                    }

                    //Store the last used id
                    this._lastUsedId = loyaltyProgramData.lastId;

                    //Sort alphabetically
                    loyaltyPrograms.sort((leftSide: LoyaltyProgram, rightSide: LoyaltyProgram): number => {
                        if (leftSide.name < rightSide.name) return -1
                        if (leftSide.name > rightSide.name) return 1
                        return 0;
                    });

                    return loyaltyPrograms;
                }));
        }
    }





}