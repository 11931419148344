import { ClientsService, Client } from '@admin/clients/clients.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DebugService as debug } from "@core/services/debug.service";
import { ClientFeatures } from '@shared/models/guards';
import { AuthConfig } from '@auth/auth.config';
import { isMatch } from 'matcher';

@Injectable({
    providedIn: 'root'
})
export class ClientFeatureGuard implements CanActivate {

    protected guardName: string = 'Client feature';
    protected _lastUrl: string;
    protected _lastFeatures: ClientFeatures[];

    constructor(
        private _clientService: ClientsService,
        private _router: Router
    ) {
        this._clientService.clients.onActiveElementChange().subscribe((client: Client) => {
            if (!this._lastUrl || !isMatch(this._router.url, this._lastUrl)) return;
            if (!this.hasFeatures(client, this._lastFeatures))
                this._router.navigate([AuthConfig.paths.error.forbidden])
        })
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

        let path = route.routeConfig?.path
        let requiredFeatures: ClientFeatures[] = route.data?.requiredFeatures || [];
        let activeClient = this._clientService.clients.activeElement;

        this._lastUrl = `${state.url.substring(0, state.url.indexOf(path))}${path}*`;
        this._lastFeatures = requiredFeatures

        let hasFeatures = this.hasFeatures(activeClient, requiredFeatures);

        if (!hasFeatures) {
            this._router.navigate([AuthConfig.paths.error.forbidden])
        }

        return hasFeatures

    }

    hasFeatures(client: Client, features: ClientFeatures[]): boolean {

        let hasFeatures: boolean = true;
        let hasFeaturesMap: Record<string, boolean> = {};

        for (let feature of features) {
            let hasFeature: boolean;
            switch (feature) {
                case "customers":
                    hasFeature = this.hasCustomers(client);
                    hasFeaturesMap[feature] = hasFeature
                    break;
                default:
                    break;
            }
            hasFeatures = hasFeatures && hasFeature;
        }

        debug.log(`Guard - ${this.guardName}:`, hasFeaturesMap)

        return hasFeatures

    }

    hasCustomers(client: Client): boolean {
        return !["management"].includes(client?.type)
    }

}
