// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { version } from './version';

export const environment = {
    production: false,
    ADMIN_URL: '.dev.carwashintelligence.com',
    APP_URL: 'https://dev.api.carwashconnect.ca/',
    APP_VERSION: version,
    API_KEY: 'admin',
    DEBUG: true,
    HEAP_ID: '3291282562',
    STRIPE_CLIENT_ID: 'ca_7dU09gIPxznXCfiEMH0c7ZNCmGmjotr4',
    STRIPE_REDIRECT_URI: 'https://admin.dev.carwashintelligence.com/redirect',
    DASHBOARD_WS_ENDPOINT: 'wsd.api.carwashconnect.ca',
};
